.contact-wrapper {
	.contact-heading {
		color: $c-black;
		text-transform: uppercase;
		font-size: 1.5rem;
		margin-bottom: 0;
	}

	.contact-number {
		display: inline-block;
		color: $c-brand-colour;
		margin-bottom: 5px;
	}
}