.display-page {
	background-color: $c-background-grey;
}

.bg-grey {
	background-color: $c-background-grey;
}

.manual-page {
	.manual-container {
		padding: 60px 0;
	}

	.service {
		padding: 0;
	}

	.service-containter {
		padding-top: 20px;
	}

	.result-block {
		margin-bottom: 0;
	}

	.button {

		&.-orange {
			background-color: $c-background-grey;

			@include mobile {
				margin-top: 20px;
			}
		}
	}

	.search-module-inner {
		background: #E7E7E7;

		.form-container {
			margin: 30px 0;

			.form-field {
				margin-top: 0;
			}
		}
	}
}

.html-template {
	padding: 20px 0 20px 0;
	@include font-din-regular;
	font-size: 1.8rem;

	@include mobile {
		padding: 20px;
	}
}