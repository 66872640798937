.service-agent {
	@include mobile {
		position: relative;
		width: 100vw;
		left: -20px;
	}

	.single-accordion {
		.accordion-title {
			@include font-din-medium;
			font-size: 1.6rem;
			display: block;
			width: 100%;
			background: $c-charcoal;
			padding: 15px 50px 15px 20px;
			color: $c-white;
			text-decoration: none;
			background-image: url('../../image/icons/down-chevron-white.svg');
			background-repeat: no-repeat;
			background-position: calc(100% - 20px) 50%;
			transition: 0.2s opacity ease-in-out;
			border-bottom: 1px solid $c-line;

			@include tablet {
				font-size: 1.3rem;
			}

			&:hover,
			:focus {
				opacity: 0.8;
			}

			&.-active {
				background-image: url('../../image/icons/up-chevron-white.svg');
			}
		}
		
		.agents-container {
			display: flex;
			flex-wrap: wrap;
			
			.single-service-agent {
				width: calc(50% - 30px);

				&:nth-child(odd) {
					margin: 20px 20px;
				}

				&:nth-child(even) {
					margin: 20px 20px 20px 0;
				}

				&:nth-child(even),
				&:nth-child(odd) {
					@include mobile {
						margin: 20px 20px;
					}
				}

				@include mobile {
					width: 100%;
				}

				.agent-line {
					padding: 10px 20px;
					border-bottom: 1px solid $c-line;
					display: flex;

					&.-service,
					.-contact {
						min-height: 80px;
					}

					&.-address {
						min-height: 108px;
					}

					&.-service,
					.-contact,
					.-address {
						@include mobile {
							min-height: initial;
						}
					}
					
					&:first-of-type {
						border-top: 1px solid $c-line;
					}

					&.-top {
						background-color: $c-background-grey;
					}
				}

				.single-service-agent-icon {
					width: 15px;
					height: auto;
					margin: 5px 0 auto 0;
					padding-right: 15px;
				}

				.single-service-agent-title {
					@include font-din-medium;
					color: $c-black;
					font-size: 1.6rem;
					text-transform: initial;
					margin: 5px 0;

					@include tablet {
						font-size: 1.3rem;
					}
				}

				.agent-line-text {
					@include font-din-regular;
					display: flex;
					flex-direction: column;
					justify-content: flex-start;

					.single-service-agent-details {
						@include font-din-regular;
						font-size: 1.6rem;
						color: $c-grey-dark;
						margin: 0 0 2px;

						@include tablet {
							font-size: 1.3rem;
						}
					}
				}
				.single-service-agent-link {
					@include font-din-medium;
					color: $c-brand-colour;
					transition: 0.2s opacity ease-in-out;
					margin-top: 5px;

					&:hover,
					:focus {
						opacity: 0.8;
					}
				}
			}
		}
	}
}