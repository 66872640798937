// BREAKPOINTS
$bp-tablet-large: 1150px;
$bp-tablet: 1000px;
$bp-mobile: 800px;
$bp-mobile-medium: 600px;
$bp-mobile-small: 450px;

// use '@include mobile' in place of regular media queries
@mixin tablet-large {
	@media (max-width: #{$bp-tablet-large}) {
		@content;
	}
}

@mixin tablet {
	@media (max-width: #{$bp-tablet}) {
		@content;
	}
}

@mixin mobile {
	@media (max-width: #{$bp-mobile}) {
		@content;
	}
}

@mixin mobile-medium {
	@media (max-width: #{$bp-mobile-medium}) {
		@content;
	}
}


@mixin mobile-small {
	@media (max-width: #{$bp-mobile-small}) {
		@content;
	}
}

// HEADER / FOOTER SPECIFIC BREAKPOINTS
$hf-mobile: 1070px;

@mixin hf-mobile {
	@media (max-width: #{$hf-mobile}) {
		@content;
	}
}

// HERO SPECIFIC BREAKPOINTS
$hero-large: 1300px;
$hero-medium: 1000px;

@mixin hero-large {
	@media (max-width: #{$hero-large}) {
		@content;
	}
}

@mixin hero-medium {
	@media (max-width: #{$hero-medium}) {
		@content;
	}
}