.search-page {
	min-height: 500px;

	> .center-container {
		padding-bottom: 40px;
	}

	.title-section {
		display: flex;
		align-items: center;
		padding: 0 0 40px;

		@include mobile {
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
		}

		h1 {
			color: $c-brand-colour;
			font-size: 3rem;
			margin-right: 20px;
			margin-top: 0;
			margin-bottom: 4px;
		}

		.header-title {
			color: $c-black;
			font-family: 'DIN Pro Medium';
			font-weight: 500;
			font-size: 1.6rem;
			text-transform: uppercase;
			margin: 0;
		}
	}
	


	.search-page-left {
		grid-column: 2/span 10;

		@include mobile {
			grid-column: 1/span 12;
		}
	}

	.search-results-products {
		.center-container {
			padding: 0;
		}

		.card-container {
			padding-bottom: 0;
		}

		.service {
			padding-top: 0;
			padding-bottom: 0;

			grid-column: 3/span 8;

			@include mobile {
				grid-column: 1/span 12;
			}
		}

		&.search-results-block {
			padding-bottom: 20px;
		}
	}


	.search-page-right {
		grid-column: 10/span 2;
		padding-top: 50px;

		@include mobile {
			grid-column: 1/span 12;
		}
	}

	.search-result-container {
		.product-container {
			flex: 2;

			.product-block {
				text-decoration: none;
				color: $c-black;
			}
		}

		.drop-down-selection-block {
			flex: 1;

			@include mobile {
				display: none;
			}
		}
	}

	.search-results-block {
		padding-bottom: 40px;

		.card-wrapper {
			width: 100%;
		}
	}

	.search-link-component {
		a {
			text-decoration: none;
			display: inline-block;
			margin-bottom: 30px;

			@include mobile {
				margin-bottom: 20px;
			}

			&:hover,
			&:active {
				.title {
					opacity: .8;
				}
			}
		}

		.title {
			font-size: 3rem;
			color: $c-brand-colour;
			padding: 10px 40px 10px 0;
			margin: 0 0 13px;
			line-height: 110%;
			margin-bottom: 0;
			transition: opacity .25s linear;

			@include mobile {
				font-size: 2.4rem;
			}
		}

		p {
			color: $c-black;
			margin: 0;
		}
	}
}