.text-block,
.markdown-block {
	p {
		margin: 15px 0;
	}

	ul {
		padding-left: 60px;
		margin: 15px 0;

		@include mobile {
			padding-left: 40px;
		}

		.recipes & {
			list-style: none;
		}

		li {
			.recipe-page & {
				padding: 15px 0;
				border-bottom: 1px solid $c-line;
			}
		}
	}

	ol {
		padding-left: 60px;
		margin: 15px 0;

		@include mobile {
			padding-left: 40px;
		}
			
		li {
			.recipe-page & {
				padding: 15px 0 15px 10px;
				border-bottom: 1px solid $c-line;
			}
		}
	}

	img {
		width: 100%;
		height: auto;
		border-radius: 10px;
		margin: 20px 0;
	}

	a {
		color: $c-black;
		text-decoration: underline;
		transition: 0.2s color ease-in-out;

		&:hover,
		:focus {
			color: $c-brand-colour;
		}
	}


	h1,
	h2,
	h3 {
		@include font-din-regular;
		font-size: 4.3rem;
		color: $c-brand-colour;
		text-transform: capitalize;
		margin: 35px 0 15px 0;

		@include tablet{
			margin: 0 0 10px 0;
			font-size: 3rem;
		}
	}

	h4,
	h5,
	h6  {
		@include font-din-medium;
		font-size: 1.6rem;
		margin: 20px 0 0 0;
		color: $c-black;
	}

	// h5,
	// h6 {
	// 	@include font-din-regular;
	// 	font-size: 1.5rem;
	// 	margin: 0 0 15px 0;
	// 	text-transform: uppercase;
	// 	color: $c-black;

	// 	@include tablet{
	// 		margin: 0 0 10px 0;
	// 	}
	// }
}