.product-buy-sticky-wrapper {
	display: none;
	position: fixed;
	bottom: 15px;
	left: 0;
	right: 0;
	z-index: 5;
	width: 100%;
	padding: 0 20px;

	.button {
		width: 100%;
		margin: 0 auto;
	}

	@include mobile {
		display: inline-block;
	}
}

.product-navigation {
	position: sticky;
	top: 0;
	z-index: 101;
	display: flex;
	background: linear-gradient(to right, 
	$c-background-grey 50%, $c-white 50%);

	@include mobile {
		display: none;
	}

	.product-navigation-wrapper {
		display: flex;
		background-color: $c-background-grey;
	}

	.product-navigation-link-container {
		display: flex;
		width: 80%;
		list-style: none;
		margin: 0;
		justify-content: space-around;
		flex-wrap: wrap;

		.product-navigation-link {
			display: flex;
			margin: 0;
			padding: 0 20px;

			a {
				text-decoration: none;
				margin: auto auto auto 0;
				text-transform: uppercase;
				color: $c-grey-dark;
				font: 1.5rem;
				border-top: 1px solid $c-background-grey;
				border-bottom: 1px solid $c-background-grey;
				white-space: pre-wrap;
				padding: 9px 0;

				&:hover,
				&:focus {
					border-bottom: $c-orange 1px solid;
				}

				@include tablet {
					font-size: 1.4rem;
					padding: 10px 0;
				}
			}
		}
	}

	.product-nav-button-container {
		background-color: $c-white;
		border-radius: 50px 0 0 50px;
		width: 20%;
		padding: 18px 20px 18px 30px;
		// margin: auto;
		min-width: 210px;

		.product-navigation-button {
			@include font-din-medium;
			font-size: 1.6rem;
			padding: 10px 10px 10px 20px;
			text-decoration: none;
			width: 100%;
			margin: auto;
			text-align: left;
			min-width: initial;
			background-color: $c-orange;
			color: $c-white;
			border-color: $c-orange;
			box-shadow: 5px 5px 5px rgba(209,211,212,0.5);
			background-image: url('../../../image/icons/right-chevron-white.svg');

			&:hover,
			:focus {
				background-image: url('../../../image/icons/right-chevron-black.svg');
				background-color: $c-white;
				color: $c-orange;
			}
		}
	}
}