.swiper-pagination-bullets {
	width: 270px;
	height: 10px;
	position: absolute;
	bottom: 15px;
	display: flex;
	left: 0;
	right: 0;
	margin: 0 auto;
	justify-content: center;

	.swiper-pagination-bullet {
		width: calc(100% / 3 - 5px);
		height: 5px;
		display: inline-block;
		background-color: $c-white;
		margin: 0 5px 0 0;
		border-radius: 10px;

		&:last-child {
			margin-right: 0;
		}

		&:hover,
		:focus {
			outline: 0;
			cursor: pointer;
		}

		&.swiper-pagination-bullet-active {
			background-color: $c-orange;
		}
	}
}

.swiper-container {
	&:hover,
	:focus {
		cursor: grab;
	}
}