.inclusions {
	@include mobile-accordion;
	@include section-accordion;

	.inclusions-inner {
		.single-inclusion {
			display: flex;
			padding: 20px 20px 20px 0;
			border-bottom: 1px solid $c-line;

			.single-inclusion-image {
				height: 80px;
				width: 80px;
				object-fit: cover;
				margin-right: 30px;
			}
			.inclusion-details {
				color: $c-grey-dark;
				display: flex;
				align-items: center;
			}
		}
	}

	.accordion-title {
		text-transform: initial;

		span {
			text-transform: initial;
		}
	}
}