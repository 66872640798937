.product-range-module-container {
	background-color: $c-white;

	.product-range-products-container {

		&.-desktop {
			display: flex;
			flex-wrap: wrap;

			@include mobile {
				display: none;
			}

			.product-range-product {
				width: calc(100% / 3);

				&:hover,
				&:focus {
					p {
						color: $c-brand-colour;
					}
				}

				// &:nth-child(1),
				// &:nth-child(2),
				// &:nth-child(3) {
				// 	border-top: none;
				// }

				&:nth-child(3n) {
					border-right: none;
				}
			}
		}

		&.-mobile {
			display: none;

			@include mobile {
				display: block;
			}
		}

		.product-range-product {
			text-decoration: none;
			border-right: 1px solid $c-line;
		}

		.product-range-product-inner {
			padding: 9%;
			width: 100%;
			height: 100%;

			.product-range-product-image {
				width: 75%;
				height: auto;
				display: block;
				margin: auto;
				object-fit: contain;
				border-radius: 20px;

				@include mobile {
					border-radius: 10px;
				}
			}

			.product-range-product-title {
				text-align: center;
				text-transform: uppercase;
				text-decoration: none;
				display: block;
				color: $c-grey-dark;
				font-size: 2rem;
				transition: 0.2s color ease-in-out;
				margin-top: 20px;
				margin-bottom: 0;
				@include font-din-medium;

				&:hover,
				:focus {
					color: $c-brand-colour;
				}
			}
		}
		.swiper-wrapper {
			display: inline-flex;
		}
	}

	.product-range-headings-container {
		@include mobile {
			margin-bottom: 30px;
		}
	}
}