.help-module {
	background-color: $c-grey-light;
	border-top: 1px solid $c-line;

	&.-white {
		background-color: $c-white;
	}

	&.-grey,
	&.grey-bg {
		background-color: $c-grey-light;
	}

	.help-module-inner {
		padding-top: 40px;
		padding-bottom: 40px;

		@include tablet {
			padding-top: 20px;
			padding-bottom: 20px;
		}

		@include mobile {
			flex-direction: column;
		}
	}

	/*.help-module-left,
	.help-module-right {
		width: 50%;

		@include mobile {
			width: 100%;
		}
	}*/

	.help-module-left {
		padding-right: 30px;
	}

	.help-module-heading {
		@include font-din-regular;
		font-size: 4.3rem;
		color: $c-brand-colour;
		max-width: 550px;
		margin-top: 35px;
		margin-bottom: 35px;

		@include tablet {
			font-size: 3rem;
		}

		@include mobile {
			margin-top: 0;
			margin-bottom: 15px;
		}
	}

	.help-module-right {
		@include grid-child(7, 4);
		display: flex;
		flex-direction: column;
		justify-content: center;
		list-style: none;

		@include mobile {
			padding-left: 0;
		}
	}

	.help-module-form {
		width: 100%;

		@include mobile {
			width: 100%;
		}
	}

	.help-module-link {
		margin: 12px 0;
		position: relative;
		display: flex;
		align-items: center;

		&:before {
			background: url('../../image/icons/support-icon-arrow.svg') scroll 0 0 no-repeat;
			content: '';
			width: 23px;
			height: 23px;
			margin-right: 10px;
			position: relative;
			top: 1px;
			flex-shrink: 0;
		}

		@include mobile {
			margin-left: 30px;
		}

		a {
			@include font-din-medium;
			font-size: 3rem;
			text-decoration: none;
			color: $c-font-grey;
			transition: 0.2s color ease-in-out;
			line-height: 130%;

			@include tablet {
				font-size: 2.4rem;
			}

			&:hover,
			:focus {
				color: $c-orange;
			}
		}
	}
}