.footer {
	width: 100%;

	.footer-top {
		background: linear-gradient(to right, 
		$c-grey-regular 50%, $c-background-grey 50%);
		display: flex;

		.centred-flex {
			@include hf-mobile {
				flex-direction: column;
			}
		}

		.footer-left {
			background-color: $c-grey-regular;
			width: (7 / 12 * 100%);
			padding-right: 20px;

			@include hf-mobile {
				width: 100vw;
				position: relative;
				left: -40px;
				padding: 0 40px;
			}

			@include mobile {
				left: -20px;
				padding: 0 20px;
			}

			.footer-links-container {
				max-width: 800px;
				display: flex;
				justify-content: space-between;

				@include hf-mobile {
					max-width: 100%;
					display: flex;
					flex-direction: column;
				}

				.footer-link-container {
					margin: 70px 0;
					padding-right: 10px;
					max-width: 200px;

					@include hf-mobile {
						width: 100%;
						margin: 0;
						padding: 20px 0;
						border-bottom: 1px solid $c-white;
						max-width: none;

						&:last-of-type {
							border-bottom: none;
						}
					}

					@include footer-accordion;

					.footer-link-category {
						@include font-din-medium;
						font-size: 1.6rem;
						text-transform: uppercase;
						color: $c-brand-colour;
						margin-bottom: 40px;

						@include hf-mobile {
							margin: 0;
							background-image: url('../../image/icons/down-chevron-red.svg');
							background-repeat: no-repeat;
							background-position: right center;
							cursor: pointer;
						}
					}

					.mobile-accordion-header {
						&:not(.-hide) {
							.footer-link-category {
								@include mobile {
									background-image: url('../../image/icons/up-chevron-red.svg');
								}
							}
						}
					}

					.footer-link-list {
						list-style: none;
						margin-bottom: 0;

						.footer-link {
							margin: 20px 0;
							box-sizing: border-box;

							&:last-child {
								margin-bottom: 0;
							}

							a {
								color: $c-black;
								text-decoration: none;

								&:hover,
								:focus {
									text-shadow: -0.2px 0 $c-black, 0.2px 0 $c-black;
								}
							}
						}
					}
				}
			}
		}

		.footer-right {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: (5 / 12 * 100%);
			background-color: $c-background-grey;
			padding-left: 40px;

			@include hf-mobile {
				width: 100vw;
				position: relative;
				left: -40px;
				padding: 0 40px;
			}

			@include mobile {
				left: -20px;
				padding: 0 20px;
			}

			.footer-contact {
				height: 100%;
				display: flex;
				flex-direction: column;
				padding: 87px 0;
				//justify-content: center;

				@include hf-mobile {
					// margin-left: 5%;
					margin: 0;
					padding: 40px 0;
					width: 100%;
				}

				

				.footer-social-container {
					display: flex;
					align-items: center;
					margin-top: 40px;

					@include hf-mobile {
						margin-top: 20px;
					}

					.footer-social-heading,
					.footer-social-link {
						display: inline-block;
						margin-right: 30px;
						margin-bottom: 0;
					}

					.footer-social-link {
						transition: 0.2s opacity ease-in-out;

						&:hover,
						:focus {
							opacity: 0.8;
						}
					}

					.footer-social-heading {
						@include font-din-medium;
						font-size: 1.6rem;
						text-transform: uppercase;
						color: $c-brand-colour;
						margin-top: 0;
					}
				}
			}
		}
	}

	.footer-bottom {
		display: flex;
		padding: 25px 0;

		@include hf-mobile {
			padding-bottom: 40px;
		}

		.centred-flex {
			@include hf-mobile {
				flex-direction: column;
			}
		}

		.footer-copyright-container {
			@include hf-mobile {
				padding-bottom: 10px;

				p {
					font-size: 1.4rem;
				}
			}

			.footer-copyright {
				color: $c-grey-dark;
				margin: auto 0;
			}
		}

		.footer-legal {
			margin-left: auto;

			@include hf-mobile {
				display: flex;
				flex-direction: column;
				margin-left: 0;
			}

			.footer-legal-link {
				color: $c-grey-dark;
				text-decoration: none;
				margin: 0 35px;
				transition: 0.2s opacity ease-in-out;

				@include hf-mobile {
					margin: 5px 0 15px;
					font-size: 1.4rem;
				}

				&:last-of-type {
					@include hf-mobile {
						margin-bottom: 0;
					}
				}

				&:hover,
				:focus {
					opacity: 0.8;
				}
			}
		}
	}
}

.contact {

	.contact-heading {
		@include font-din-regular;
		font-size: 3rem;
		width: 300px;
		color: $c-brand-colour;
		margin-bottom: 40px;
		margin-top: 0;
		line-height: 3.4rem;

		@include hf-mobile {
			font-size: 2.4rem;
			line-height: 2.8rem;
			margin-bottom: 20px;
		}
	}

	.contact-mini-heading {
		@include font-din-regular;
		font-size: 1.5rem;
		text-transform: uppercase;
		margin: 0;
	}

	.contact-number {
		font-size: 5.6rem;
		color: $c-black;
		text-decoration: none;
		transition: 0.2s opacity ease-in-out;
		margin-left: -1rem;
		margin-bottom: 7px;

		&.-red {
			color: $c-brand-colour;
		}

		&:hover,
		:focus {
			opacity: 0.8;
		}

		@include hf-mobile {
			font-size: 5rem;
		}
	}

	.contact-hours {
		color: $c-grey-dark;
		margin: 0;
		margin-bottom: 3px;

		&:last-of-type {
			margin-bottom: 20px;
		}
	}
}