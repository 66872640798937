.loader-container {
	height: calc(100vh - 80px);
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $c-white;

	@include hf-mobile {
		height: calc(100vh - 60px);
	}
}