.service {
	@include mobile-accordion;
	@include section-accordion;

	.manual-download {
		background-color: $c-background-grey;
		padding: 0 0 40px;

		@include mobile {
			padding: 0 0 20px;
		}

		.manual-download-container {
			background-color: $c-white;
			width: 100%;
			height: 100%;
			padding: 15px;
			position: relative;
			border-radius: 10px;

			.manual-download-inner {
				display: flex;
				width: 100%;
				position: relative;

				.manual-download-image {
					width: auto;
					height: 145px;
					object-fit: contain;

					@include mobile {
						height: 80px;
					}
				}

				.manual-download-content {
					padding-left: 20px;
					width: 100%;
					display: flex;
					flex-direction: column;

					@include mobile {
						padding-left: 10px;
					}

					.manual-download-top {
						.manual-download-product-code {
							color: $c-grey-dark;
							text-transform: uppercase;
							margin: 0 0 15px 0;

							span {
								text-transform: none;
								margin-left: 5px;
							}
						}
						.manual-download-product-title {
							margin: 0 0 15px 0;
						}
					}

					.manual-download-bottom {
						width: 100%;
						margin-top: auto;

						.single-manual-download {
							padding: 10px 0;
							border-top: 1px solid $c-line;
							width: 100%;
							display: inline-block;

							.manual-download-button {
								@include font-din-medium;
								color: $c-black;
								text-decoration: none;
								margin: auto 0;
								display: flex;
								align-items: center;
								background-image: url('../../../image/icons/right-chevron-black.svg');
								background-repeat: no-repeat;
								max-width: 270px;
								background-position: calc(100% - 5px) 50%;
								transition: 0.2s opacity ease-in-out, 0.2s background-image ease-in-out, 0.2s color ease-in-out;
								padding-right: 20px;
								line-height: 130%;

								&:hover,
								:focus {
									color: $c-orange;
									background-image: url('../../../image/icons/right-chevron-orange.svg');
								}

								.manual-download-icon {
									width: 35px;
									height: auto;
									margin: auto 20px auto 0;
								}
							}
						}
					}
				}
			}
		}
	}

	.manual-download-outro {
		padding: 40px 0 20px;

		.manual-download-outro-copy {
			margin: 0 0 20px 0;
		}
		.manual-download-outro-button {
			width: 205px;
		}
	}
}