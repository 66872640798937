.accordion-container {
	.single-accordion {
		display: flex ;
		border-bottom: 1px solid $c-line;

		.single-accordion-image {
			padding: 0 20px 20px 0;
			margin-top: 20px;

			@include mobile {
				padding: 0 20px 20px 20px;
			}

			img {
				width: 80px;
				height: 80px;
				object-fit: contain;
			}
		}

		&:last-of-type {
			border-bottom: 0;

			@include mobile {
				margin-bottom: 50px;
			}
		}

		.accordion-text {
			width: 100%;

			.accordion-title {
				@include font-din-medium;
				font-size: 2rem;
				color: $c-black;
				text-transform: uppercase;
				text-decoration: none;
				display: block;
				background-image: url('../../image/icons/down-chevron-black.svg');
				background-repeat: no-repeat;
				background-position: calc(100% - 20px) 50%;
				transition: 0.2s opacity ease-in-out;
				margin: 35px 0;
				padding-right: 50px;

				@include mobile {
					font-size: 1.6rem;
				}

				&.-active {
					background-image: url('../../image/icons/up-chevron-black.svg');
				}

				span {
					max-width: 750px;
				}
			}

			.accordion-dropdown-text {
				color: $c-grey-dark;
				max-width: 750px;
				padding-right: 50px;
				padding-bottom: 20px;
			}
		}
	}
}