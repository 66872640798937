.card-container {
	position: relative;
	background: #F8F7F7;
	padding-top: 0;

	h3 {
		@include font-din-medium;
		font-size: 1.6rem;
		text-transform: uppercase;
		margin: 0;
		color: $c-white;
	}

	.filter-title {
		color: $c-black;
	}

	.card-wrapper {
		justify-content: space-between;

		> p {
			color: $c-font-grey;
		}
	}

	.color-block {
		font-size: 1.2rem;
		width: 25%;
		margin-top: 10px;

		.single-product-colour {
			width: 46px;
			height: 45px;
			border-radius: 100%;
			display: inline-block;
			margin-bottom: 10px;

			@include tablet {
				height: 45px;
				width: 45px;
			}

			.colour-inside {
				position: relative;
				width: 38px;
				height: 37px;
				border-radius: 50%;
				border: 1px solid $c-line;

				@include tablet {
					height: 37px;
					width: 37px;
				}
			}

			&.-active {
				border: 1px solid $c-black;
				padding: 3px;
			}

			
		}
	}
	
	.inner-content {
		display: flex;

		&.-info {
			margin-bottom: 40px;
			padding-bottom: 10px;
			border-bottom: 1px solid #d0d2d3;
		}
	}

	.result {
		display: flex;
		align-items: center;
		justify-content: space-between;

		span {
			font-size: 1.6rem;
			color: #58595B;
		}

		h3 {
			margin-top: 0;
		}

		.reset-icon {
			height: 20px;
			padding-right: 10%;
			cursor: pointer;
		}
	}

	.filter-tag-header {
		display: flex;

		h3 {
			color: $c-font-grey;
		}

		img {
			width: 22px;
			margin-right: 20px;
		}
	}

	.filter-wrapper {
		flex: 1;

		.filter-wrapper-inner {
			margin-bottom: 40px;
			width: 90%;
		}

		@include mobile {
			display: none;
		}
	}

	.filter-mobile {
		display: none;

		@include mobile {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			width: 100%;
		}

		.filter-button-container {
			width: 100%;
			max-width: 360px;
		}

		.button {
			border-radius: 0;
			width: 50%;
			border-color: $c-background-grey;
			min-width: 0;
			text-transform: uppercase;

			&.-left {
				border-top-left-radius: 20px;
				border-bottom-left-radius: 20px;
				border-right: 1px solid $c-background-grey;
			}

			&.-right {
				border-top-right-radius: 20px;
				border-bottom-right-radius: 20px;
				border-left: 1px solid $c-background-grey;
			}

			&.-single {
				width: 100%;
				border-radius: 50px;
				max-width: 360px;
			}
		}
	}

	.filter-wrapper-mobile {
		display: none;

		@include mobile {
			position: fixed;
			z-index: 102;
			display: flex;
			align-items: center;
			flex-direction: column;
			height: 100vh;
			left: 0px;
			top: 0;
			width: 100%;
			padding: 0;
			background: $c-grey-light;
			overflow: scroll;
			padding-bottom: 60px;
		}

		.filter-wrapper-mobile-header {
			background: #58595B;
			padding: 10px;
			color: $c-white;
			margin-bottom: 50px;

			img {
				width: 20px;
				margin-right: 15px;
			}
		}

		.filter-wrapper-inner {
			width: 100%;
			padding: 0 20px;
			margin-bottom: 40px;
		}

		.apply-button-container {
			margin-top: 50px;
			width: 100%;
			text-align: center;

			@include tablet {
				padding: 0 20px;
			}
		}
	}

	.filter-dropdown {
		width: 60%;
	}

	.tag-container {
		display: flex;
		flex-direction: column;
		margin-top: 20px;
		padding-left: 39px;

		&.-price {
			margin-top: 22px;
		}

		@include tablet {
			padding-left: 3px;
		}
	
		.container {
			color: $c-font-grey;
			font-size: 1.6rem;
			min-height: 30px;
			display: flex;
			align-items: center;
			position: relative;
			padding-left: 40px;
			margin-bottom: 18px;
			cursor: pointer;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}

		/* Hide the browser's default radio button */
		.container input {
			position: absolute;
			display: inline-block;
			opacity: 0;
			cursor: pointer;
		}

		/* Create a custom radio button */
		.checkmark {
			position: absolute;
			top: 0;
			left: 0;
			height: 30px;
			width: 30px;
			background-color: $c-white;
			border: 1px solid #d0d2d3;
			border-radius: 50%;
			box-sizing: border-box;
		}

		/* On mouse-over, add a grey background color */
		.container:hover input ~ .checkmark:after {
			visibility: visible;
			opacity: .3;
		}

		/* When the radio button is checked, add a blue background */
		.container input:checked ~ .checkmark {
			background-color: $c-white;
			border: 1px solid $c-grey-dark;
		}

		/* Create the indicator (the dot/circle - hidden when not checked) */
		.checkmark:after {
			content: '';
			position: absolute;
			// display: none;
			visibility: hidden;
		}

		/* Show the indicator (dot/circle) when checked */
		.container input:checked ~ .checkmark:after {
			visibility: visible;
			opacity: 1;
		}

		/* Style the indicator (dot/circle) */
		.container .checkmark:after {
			top: 7px;
			left: 7px;
			width: 14px;
			height: 14px;
			border-radius: 50%;
			background: $c-brand-colour;
		}
	}

	// Rc-slider
	.rc-slider-wrapper {
		width: calc(100% - 19px);
		margin: 0px 10px;

		.rc-slider,
		.rc-slider-step {
			height: 26px;
		}

		.rc-slider-rail {
			height: 17px;
			border-radius: 50px;
			background-color: $c-white;
			border: 1px solid $c-line;
		}

		.rc-slider-track {
			height: 16px;
		}
		
		.rc-slider-handle {
			height: 30px;
			width: 30px;
			top: 3px;
			border-color: $c-background-grey;
			background-color: $c-grey-dark;
		}

		.rc-slider-dot {
			visibility: hidden;
		}

		.rc-slider-handle-dragging {
			box-shadow: none !important;
			border-color: $c-background-grey !important;
		}

		.rc-slider-mark-text {
			top: 15px;

			strong {
				@include font-din-regular;
				font-size: 1.6rem !important;
				color: $c-font-grey !important;
			}
		}

		.rc-slider-tooltip-inner {
			height: 31px;
			min-width: 31px;
			font-size: 1.6rem;
		}
	}

	.load-more-container {
		width: 75%;
		margin-left: auto;
		margin-top: 40px;

		@include mobile {
			width: 100%;
			margin-bottom: 20px;
		}
	}
}
