.dynamic-block {
	position: relative;
	margin: 80px 0 120px;

	@include mobile {
		margin: 60px 0 60px;
	}

	.social-share-block {
		position: absolute;
		right: 216px;
		top: 0;

		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;

		a {
			cursor: pointer;
			padding: 20px;
			@include mobile {
				padding: 15px;
			}
		}

		.share-icon {

			@include mobile {
				width: 60px;
			}
		}

		@include mobile {
			right: 20px;
		}
	}

	.dynamic-recipe-tip,
	.dynamic-lifestyle-slider,
	.dynamic-accordion,
	.dynamic-image-break,
	.dynamic-featured-products {
		padding: 0;
		margin: 80px 0;

		@include mobile {
			margin: 60px 0;
		}
	}

	.dynamic-repeat-video {
		padding: 0;
		margin: 100px 0;

		@include mobile {
			margin: 80px 0;
		}
	}

	.dynamic-text-block,
	.dynamic-recipe-tip {
		padding: 0;
		margin: 40px 0;

		@include mobile {
			margin: 30px 0;
		}
	}

	.dynamic-large-heading {
		padding: 0;
		margin: 40px 0 20px;

		@include mobile {
			margin: 40px 0 20px;
		}
	}

	.dynamic-recipe-list,
	.dynamic-video-intro {
		margin: 80px 0;

		@include mobile {
			margin: 60px 0;
		}
	}

	// INDIVIDUAL MODULES
	// Large Heading
	.large-subheading {
		margin-bottom: 0;
	}

	// Recipe List
	.dynamic-recipe-list {
		padding: 60px 0;
		background-color: $c-background-grey;

		@include mobile {
			padding: 20px 0 40px;
		}

		.card-container {
			.inner-content {
				@include mobile {
					flex-direction: column;
				}
			}
		}
		.filter-wrapper {
			@include mobile {
				display: flex;
			}
		}
	}

	// Video Intro
	.dynamic-video-intro {
		padding: 0;
		background-color: $c-background-grey;

		.product-intro {
			.center-container {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}

	// Featured Products
	.featured-products {
		padding: 0;
	}

	// Text Block
	.dynamic-text-block {
		padding: 0;
		margin: 20px 0;

		@include mobile {
			margin: 20px 0;
		}

		.text-block {
			p,
			ol,
			ul {
				&:first-child {
					margin-top: 0;
				}
			}
		}
	}

	// Image Copy Block
	.dynamic-image-copy {
		padding: 20px 0;

		.image-copy-wrapper {
			display: flex;

			@include mobile-medium {
				flex-direction: column;
			}

			.block {
				width: 50%;

				@include mobile-medium {
					width: 100%;
				}
			}

			.image {
				@include mobile-medium {
					order: 1;
				}

				img {
					width: 100%;
					border-radius: 10px;
				}
			}

			.copy {
				padding-left: 30px;

				@include mobile-medium {
					padding-left: 0;
					order: 2;
					margin-top: 20px;
				}

				&.reverse {
					padding-right: 30px;
					padding-left: 0;

					@include mobile-medium {
						padding-right: 0;
					}
				}
			}
		}
	}

	// Accordion
	.dynamic-accordion {
		.accordion-container {
			.single-accordion {
				&:last-of-type {
					@include mobile {
						margin-bottom: 0;
					}
				}
			}
		}
	}

	// Cta
	.dynamic-cta {

		.cta-wrapper {
			margin: 15px 0;
		}
	}
}