.related-articles-module {
	background: linear-gradient(to right, 
	$c-grey-dark 50%, $c-white 50%);
	padding: 0;

	@include tablet {
		background: $c-grey-dark;
	}

	.related-articles-wrapper {
		display: flex;
		height: 70vw;
		max-height: 65vh;
		min-height: 650px;
		overflow: hidden;
		
		@include tablet {
			flex-direction: column;
			height: initial;
			height: auto;
			min-height: none;
			max-height: none;
		}
	}

	.related-articles-headings-container {
		background-color: $c-grey-dark;
		width: 25%;
		padding: 4% 4% 4% 0;
		display: flex;
		flex-direction: column;
		z-index: 1;

		@include tablet {
			max-width: 500px;
			width: 100%;
			padding: 35px 20px;
		}

		.related-articles-headings-top {
			.info-icon {
				width: 20px;
				height: 20px;
				margin-bottom: 20px;

				@include tablet {
					margin-bottom: 10px;
				}
			}

			.related-articles-heading {
				@include font-din-regular;
				font-size: 4.3rem;
				color: $c-white;
				margin: 0 0 50px 0;

				@include tablet-large {
					font-size: 3rem;
				}

				@include tablet {
					margin-bottom: 0;
				}
			}

			.related-articles-nav {
				@include tablet {
					display: none;
				}

				.related-articles-previous,
				.related-articles-next {
					width: 190px;
					height: auto;
					transition: 0.2s opacity ease-in-out;

					&:hover,
					:focus {
						opacity: 0.8;
					}
				}

				.related-articles-previous {
					margin-right: 20px;
				}
			}
		}
	}

	.related-articles-headings-bottom {
		@include desktop-and-mobile-tablet;
		margin-top: auto;

		&.-mobile {
			background-color: $c-grey-dark;
			padding: 30px 20px;
		}


		.related-articles-link {
			color: $c-white;
			text-decoration: none;
			display: block;
			background-image: url('../../image/icons/right-chevron-white.svg');
			background-repeat: no-repeat;
			background-position: calc(100% - 20px) 50%;
			transition: 0.2s background-color ease-in-out;
			max-width: 210px;

			&:hover,
			:focus {
				color: $c-orange;
				background-image: url('../../image/icons/right-chevron-orange.svg');
			}

			&:first-of-type {
				margin-bottom: 15px;
			}
		}
	}

	.related-articles-container {
		background-color: $c-white;
		width: 75%;

		@include tablet {
			width: 100%;
		}

		.swiper-container {
			height: 100%;

			.swiper-wrapper {
				display: inline-flex;
				height: 100%;
			}
		}

		.orange-arrow-link {
			margin-bottom: 20px;
			opacity: 1;
		}

		.related-article {
			padding: 3.5% 4% 4%;
			border: 1px solid $c-line;
			border-left: none;
			display: flex;
			flex-direction: column;
			text-decoration: none;

			@include tablet {
				padding: 30px 20px 40px;

				&:first-child {
					border-left: 1px solid $c-line;
				}
			}

			.related-article-type {
				@include font-din-regular;
				font-size: 1.5rem;
				text-transform: uppercase;
				margin: 0 0 10px 0;
				color: $c-black;
			}
			.related-article-title {
				@include font-din-regular;
				font-size: 2.4rem;
				color: $c-brand-colour;
				text-transform: none;
				margin: 0 0 20px 0;
				line-height: 130%;
			}
			.related-article-intro {
				margin: 0 0 20px 0;
				color: $c-font-grey;
			}

			.related-article-image {
				height: auto;
				width: 100%;
				max-width: 265px;
				margin-top: auto;
				border-radius: 20px;
				object-fit: cover;

				@include tablet {
					max-width: none;
				}

				@include tablet {
					border-radius: 10px;
				}
			}
		}
	}
}