.recipe-brief {
	flex-direction: column;
	margin: 50px 0 25px;

	.recipe-brief-row {
		padding-bottom: 15px;
		display: flex;

		&:last-of-type {
			padding-bottom: 0;
		}

		.recipe-brief-row-left {
			display: flex;
			min-width: 170px;

			.recipe-brief-icon {
				display: block;
				height: auto;
				width: 20px;
				object-fit: contain;
				margin-right: 10px;
				height: max-content;

				&:last-of-type {
					margin-bottom: auto;
				}
			}

			.recipe-brief-heading {
				@include font-din-medium;
				font-size: 1.6rem;
				margin-right: 5px;
				color: $c-grey-dark;
				margin: 0 0 auto 0;
			}
		}

		.recipe-brief-row-right {
			display: flex;
			flex-wrap: wrap;
			@include mobile {
				flex-direction: column;
			}

			.recipe-brief-details {
				@include font-din-medium;
				margin: 0 0 auto 0;

				&.-appliances {
					display: block;
					color: $c-black;
					margin: auto 15px auto 0;
					transition: 0.2s color ease-in-out;

					&:hover,
					:focus {
						color: $c-brand-colour;
					}
				}
			}
		}
	}
}