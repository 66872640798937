.home-hero {
	padding: 0;

	.lifestyle-slider-container {
		@include desktop-and-mobile-small;
		height: 100%;
		overflow-x: hidden;

		.swiper-wrapper {
			display: inline-flex;
		}
	}


	.home-hero-container {
		display: flex;
		width: 100%;
		background: $c-white;
		padding: 80px 0;
		min-height: 750px;

		@include hero-large {
			min-height: 650px;
		}
		
		@include hero-medium {
			padding: 40px 0 0;
			min-height: 650px;
			flex-direction: column;
		}

		.left {
			width: 50%;
			background: $c-white;
			
			@include hero-medium {
				width: 100%;
				// padding-right: 10px;
				// padding-left: 10px;
			}


			.content-block {
				border-left: 14px solid $c-brand-colour;
				background: #F8F7F7;
				padding: 5% 15% 5% 5%;
				padding-bottom: 65px;
				position: relative;
				height: 100%;
				display: flex;
				justify-content: center;
				flex-direction: column;
				align-items: flex-start;

				@include hero-large {
					padding: 5% 10% 5% 5%;
				}

				
				@include hero-medium {
					padding: 30px;
					padding-top: 45px;
					padding-bottom: 120px;
					border-left: 5px solid $c-brand-colour;
				}

				.button-container {
					margin-top: 20px;
					display: flex;
					max-width: 250px;
					position: absolute;
					bottom: 30px;

					@include hero-medium {
						bottom: 70px;
					}

					.slider-option {
						width: 80px;
						height: 8px;
						background: #D0D2D3;
						margin-right: 5px;
						border-radius: 10px;
						transition: 0.2s opacity ease-in-out;

						&:hover,
						:focus {
							cursor: pointer;
							opacity: 0.6;
						}

						&.-active {
							background: #F98E19;
						}
					}

				}
				
			}

			.header-title {
				@include font-din-medium;
				font-size: 6.5rem;
				color: $c-brand-colour;
				margin: 0 0 20px;

				@include hero-large {
					font-size: 5rem;
					margin: 0 0 10px;
				}

				@include hero-medium {
					max-width: 500px;
					font-size: 3.8rem;
				}
			}

			.d1 {
				font-style: normal;
				font-weight: normal;
				font-size: 3rem;
				margin: 0;
				max-width: 640px;
				margin-bottom: 20px;

				@include hero-large {
					font-size: 2.5rem;
					margin-bottom: 10px;
				}

				@include hero-medium {
					max-width: 500px;
				}
			}

			.d2 {
				font-style: normal;
				font-weight: normal;
				font-size: 1.6rem;
				color: #58595B;
				margin: 0;
				max-width: 640px;
				margin-bottom: 20px;

				@include hero-medium {
					max-width: 500px;
				}
			}

			.button {
				margin-top: 10px;
			}
		}

		.right {
			width: 50%;
			position: relative;
			background-size: cover;
			background-position: center;

			@include hero-medium {
				width: 100%;
				margin: 0 auto;
				height: 350px;
				margin-bottom: 50px;
			}   
		}

		.img {
			height: calc(100% + 160px);
			top: -80px;
			z-index: 1;
			width: 100%;
			position: absolute;
			object-fit: contain;

			@include hero-medium {
				height: calc(350px + 100px);
				top: -50px;
			}
		}
	}
}