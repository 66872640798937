// MIXINS
// Grid mixins
@mixin grid-parent($columns) {
	display: grid;
	-ms-display: grid;
	grid-template-columns: repeat($columns, 1fr);
	grid-template-rows: auto;
	grid-column-gap: 20px;

	@include mobile {
		grid-column-gap: 10px;
	}
}

@mixin grid-child($line, $span) {
	grid-column: $line / span $span;

	@include mobile {
		grid-column: 1 / span 12;
	}
}

// ASPECT RATIO MIXIN
@mixin aspect-ratio($width, $height) {
	position: relative;
	&:before {
		display: block;
		content: '';
		width: 100%;
		padding-top: ($height / $width) * 100%;
	}
	> .aspect-content {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

// ASPECT RATIO CLASSES
.aspect-ratio  {
	background-size: cover;
	background-position: center;
}

.sixteen-nine {
	@include aspect-ratio(16, 9);
}

.four-three {
	@include aspect-ratio(4, 3);
}

.one-one {
	@include aspect-ratio(1, 1);
}

// SHOW ON DESKTOP HIDE ON MOBILE ELEMENTS AT 767PX BREAKPOINT
@mixin desktop-and-mobile-medium {
	&.-desktop {
		display: block;

		@include mobile {
			display: none;
		}
	}

	&.-mobile {
		display: none;

		@include mobile {
			display: block;
		}
	}
}

// SHOW ON DESKTOP HIDE ON MOBILE ELEMENTS AT 767PX BREAKPOINT
@mixin desktop-and-mobile-tablet {
	&.-desktop {
		display: block;

		@include tablet {
			display: none;
		}
	}

	&.-mobile {
		display: none;

		@include tablet {
			display: block;
		}
	}
}

// SHOW ON DESKTOP HIDE ON MOBILE ELEMENTS AT 450PX BREAKPOINT
@mixin desktop-and-mobile-small {
	&.-desktop {
		display: block;

		@include mobile-small {
			display: none;
		}
	}

	&.-mobile {
		display: none;

		@include mobile-small {
			display: block;
		}
	}
}

// MOBILE ACCORDION MIXIN FOR ACCORDIONS THAT APPEAR AT MOBLILE WIDTHS
@mixin mobile-accordion {
	.mobile-accordion-header {
		text-decoration: none;
		pointer-events: none;
		cursor: default;

		@include mobile {
			pointer-events: auto;
			cursor: auto;
		}
	}


	.mobile-accordion {
		@include mobile {
			display: block;

			&.-hide {
				display: none;
			}
		}
	}
}

// MOBILE ACCORDION FOR FOOTER ACCORDIONS THAT APPEAR AT MOBILE WIDTHS
@mixin footer-accordion {
	.mobile-accordion-header {
		text-decoration: none;
		pointer-events: none;
		cursor: default;

		@include hf-mobile {
			pointer-events: auto;
			cursor: auto;
		}
	}


	.mobile-accordion {
		@include hf-mobile {
			display: block;

			&.-hide {
				display: none;
			}
		}
	}
}


//FOR USE WITH MOBILE ACCORDION MIXIN FOR FULL SECTION ACCORDIONS
@mixin section-accordion {
	padding: 60px 0;

	@include mobile {
		padding: 0;
		margin: 50px 0;
	}

	@include mobile {
		margin: 0;

		.mobile-accordion-header {
			display: block;
			background-color: $c-background-grey;
			transition: 0.2s opacity ease-in-out;

			&:not(.-hide) {
				.section-heading {
					border: none;
					background-image: url('../../image/icons/up-chevron-black.svg');
				}
			}
		}
	}
}