.recipe-tip {
	background-color: $c-background-grey;
	border-radius: 10px;
	position: relative;

	@include mobile {
		margin: 30px 20px;
	}

	.tip-icon {
		width: 22px;
		height: 22px;
		position: absolute;
		left: -11px;
		top: 25px;
	}

	.recipe-tip-inner {
		padding: 28px 40px;
		margin: 0 auto;

		.tip-heading {
			@include font-din-regular;
			font-size: 2rem;
			text-transform: uppercase;
			margin: 0 0 15px 0;
		}

		.tip-copy {
			color: $c-font-grey;
		}
	}
}