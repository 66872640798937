.product-content {
	.center-container {
		@include mobile {
			background-color: $c-grey-light;
		}
	}

	.mobile-accordion {
		@include mobile {
			background-color: $c-white;
			width: 100vw;
			position: relative;
			left: -20px;
			padding: 0 20px;
		}

		.single-accordion {
			&:last-child {
				margin-bottom: 0;
				border-bottom: 0;
			}
		}
	}

	.service {
		@include mobile {
			.manual-download {
				width: 100vw;
				position: relative;
				left: -20px;
				padding: 0;
			}
		}
	}

	.service {
		.manual-download {
			padding: 20px;
		}
	}

	.section-heading {
		@include mobile {
			font-size: 2.4rem;
		}
	}

	.bv-content-placeholder {
		.bv-action-bar-header {
			display: none !important;
		}

		.bv-write-review-label {
			font-size: 1.6rem !important;
			margin-top: 10px !important;
			color: $c-font-grey !important;
		}
	}
}