.home-promotion {
	margin: 50px 0;

	.centred-flex {
		@include mobile {
			flex-direction: column;
		}
	}

	.home-promo-left {
		width: 25%;
		display: flex;
		flex-direction: column;
		padding-right: 20px;

		@include mobile {
			width: 100%;
		}

		.home-promo-heading {
			@include font-din-regular;
			text-transform: uppercase;
			font-size: 1.5rem;
			margin: 0;

			@include mobile {
				margin: 0 0 10px 0;
			}
		}
	}

	.home-promo-right {
		width: 75%;
		position: relative;
		overflow: hidden;

		@include mobile {
			width: 100%;
		}

		.swiper-container {
			.swiper-wrapper {
				display: inline-flex;
			}
		}

		.promotion-slider-pagination {
			right: initial;
			justify-content: flex-start;
			bottom: 7px;

			.swiper-pagination-bullet {
				background-color: $c-grey-lightest;
				margin-top: auto;

				&.swiper-pagination-bullet-active {
					background-color: $c-orange;
				}
			}
		}

		.home-promo-slide {
			position: relative;
			display: flex;

			@include mobile {
				flex-direction: column;
			}

			.home-promo-text {
				width: 50%;
				padding-right: 20px;

				@include mobile {
					width: 100%;
				}

				.home-promo-title {
					@include font-din-regular;
					font-size: 4.3rem;
					color: $c-brand-colour;
					margin: 0 0 30px 0;
					max-width: 520px;

					@include tablet {
						font-size: 3rem;
						margin: 0 0 15px 0;
					}
				}
				.home-promo-copy {
					margin: 0 0 30px 0;
					max-width: 520px;

					@include tablet {
						margin: 0 0 25px 0;
					}
				}

				.home-promo-link {
					min-width: 0;
					width: 100%;
					max-width: 245px;
					margin-bottom: 100px;

					@include tablet {
						margin-bottom: 80px;
					}

					@include mobile {
						margin-bottom: 50px;
					}
				}
			}

			.home-promo-image-container {
				width: 50%;
				min-height: 400px;
				max-height: 500px;
				height: 100%;
				margin: auto;
				display: flex;
				justify-content: center;
				align-items: center;

				@include mobile {
					width: 100%;
					min-height: auto;
				}

				> a {
					height: 100%;
				}

				@include mobile {
					margin: 0 auto 50px auto;
				}

				img {
					max-height: 100%;
					max-width: 100%;
					border-radius: 20px;
					object-fit: contain;
				}
			}
		}
	}

	.home-promotions-link {
		@include font-din-medium;
		@include desktop-and-mobile-medium;
		margin-top: auto;
		text-decoration: none;
		color: $c-brand-colour;
		background-image: url('../../../image/icons/red-right-arrow.svg');
		background-repeat: no-repeat;
		background-position: right center;
		max-width: 210px;
		transition: 0.2s opacity ease-in-out;

		&.-show-desktop {
			@include mobile {
				display: none;
			}
		}

		&.-show-mobile {
			display: none;

			@include mobile {
				margin-top: 20px;
				display: inline-block;
			}
		}

		&:hover,
		&:focus {
			opacity: .8;
		}

		&.-mobile {
			@include tablet {
				margin-top: 25px;
			}
		}
	}
}