.specifications {
	@include mobile-accordion;
	@include section-accordion;

	.specifications-inner {
		.single-specification {
			border-bottom: 1px solid $c-line;
			display: flex;

			@include mobile {
				&:last-child {
					border-bottom: 0;
				}
			}

			.specification-type {
				@include font-din-medium;
				font-size: 1.6rem;
				min-width: calc(2 / 6 * 100%);
				margin: 0;
				margin-right: 30px;
				padding: 25px 20px 25px 0;

				@include mobile {
					width: calc(3 / 8 * 100%)
				}
			}
			.specification-details {
				color: $c-grey-dark;
				margin: 0;
				padding: 25px 0 25px 0;
				width: calc(4 / 6 * 100%);
				display: flex;
				align-items: center;

				@include mobile {
					width: calc(5 / 8 * 100%)
				}
			}
		}
	}
}