.faq-page {
	.faqs-section {
		padding-bottom: 80px;

		@include mobile {
			padding-bottom: 50px;
		}

		&:last-child {
			padding-bottom: 20px;
		}
		
		h2 {
			margin-top: 0;
			margin-bottom: 15px;

			@include mobile {
				margin-bottom: 0px;
			}
		}

		.single-accordion {

			&:last-of-type {
				margin-bottom: 0px;
			}
		}

		.accordion-title {
			text-transform: initial;

			span {
				text-transform: initial;
			}
		}
	}
}