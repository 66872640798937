.product-list-container {
	margin: 20px 0 0;

	@include mobile {
		margin: 10px 0 0;
	}

	.product-item {
		text-decoration: none;
		position: relative;
		padding: 2.8% 2.5%;
		display: flex;
		flex-direction: column;
		background-color: $c-white;
		border-radius: 10px;

		@media only screen and (max-width: 600px) {
			padding: 15px;
		}

		.product-item-featured {
			position: absolute;
			right: 20px;
			top: -10px;
			width: 4vw;
			max-width: 60px;

			@include tablet {
				width: 11vw;
				top: -5px;
				right: 10px;
				max-width: 40px;
			}

			@include mobile-small {
				width: 9vw;
				max-width: 35px;
			}
		}

		.product-item-code {
			color: $c-grey-dark;
			margin: 0 0 10px 0;

			@include tablet {
				font-size: 1.2rem;
			}
		}

		.product-item-image {
			width: 85%;
			height: auto;
			display: block;
			margin: 0 auto;
			object-fit: contain;
			border-radius: 10px;
		}

		.product-item-title {
			@include font-din-medium;
			font-size: 2rem;
			margin: 10px 0;
			text-transform: none;
			color: $c-grey-dark;

			@include tablet {
				font-size: 1.6rem;
			}
		}

		.product-item-info {
			text-decoration: none;
			color: $c-black;
			transition: 0.2s opacity ease-in-out;
			display: inline-block;
			margin-top: auto;

			.product-item-price {
				@include font-din-medium;
				font-size: 2rem;
				margin: 5px 0 0;

				@include tablet {
					font-size: 1.3rem;
				}
			}
		}

		.ratings-wrapper {
			img {
				max-width: 150px;
				width: 100%;
				object-fit: contain;
			}
		}
	}

	// Specific styling of product-list for site-wide module / includes swiper
	.list-module {
		.product-list-inner {
			display: flex;
			flex-wrap: wrap;
	
			@include mobile {
				display: none;
			}
	
			&.-mobile {
				display: none;
	
				@include mobile {
					display: block;
				}
	
				.swiper-wrapper {
					display: inline-flex;
				}
			}
		}

		.product-item {
			width: 25%;
			border-right: 1px solid $c-line;
			margin: 10px 0;

			&:last-of-type {
				border-right: none;
			}

			&:nth-child(4n) {
				border-right: none;
			}

			@include mobile {
				max-width: 200px;
			}
		}
	}

	// Specific styling of product-list for index page / no swiper
	.product-index & {
		margin-top: 0;
		width: 75%;

		@include mobile {
			margin: 0;
			width: 100%;
		}
	}

	.list-index {
		.product-list-inner {
			display: flex;
			flex-wrap: wrap;
		}

		.product-item {
			@media only screen and (min-width: 1001px) {
				margin-bottom: 21px;
				width: calc(100% / 3 - 14px);
				margin-right: 21px;

				&:nth-child(3n) {
					margin-right: 0;
				}
			}

			@media only screen and (min-width: 601px) and (max-width: 1000px) {
				margin-bottom: 20px;
				width: calc(50% - 10px);
				margin-right: 20px;

				&:nth-child(2n) {
					margin-right: 0;
				}
			}

			@media only screen and (max-width: 600px) {
				margin-bottom: 15px;
				width: calc(50% - 5px);
				margin-right: 10px;

				&:nth-child(2n) {
					margin-right: 0;
				}
			}

			.product-item-image {
				margin-bottom: 5px;
			}
		}
	}
}