.lifestyle-slider {
	padding: 0;

	.lifestyle-slider-container {
		height: 100%;
		overflow: hidden;

		&.-desktop {
			@include mobile-medium {
				display: none;
			}
		}

		&.-mobile {
			display: none;
	
			@include mobile-medium {
				display: block;
			}
		}

		.swiper-wrapper {
			display: inline-flex;
			justify-content: flex-start;
			align-items: flex-start;
			position: relative;
			top: 3px;
		}

		.swiper-slide {
			// max-height: 100vh;
			object-fit: contain;
		}
		
		.lifestyle-slider-prev,
		.lifestyle-slider-next {
			position: absolute;
			top: 0;
			bottom: 0;
			background-repeat: no-repeat;
			background-size: contain;
			height: 50px;
			width: 50px;
			outline: 0;
			margin: auto 0;

			@include mobile {
				height: 35px;
				width: 35px;
			}

			&.swiper-button-disabled {
				opacity: 0.2;
			}
		}

		.lifestyle-slider-prev {
			background-image: url('../../image/icons/slider-prev.svg');
			left: 20px;
		}


		.lifestyle-slider-next {
			background-image: url('../../image/icons/slider-next.svg');
			right: 20px;
		}
	}
}