.product-retailer {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.96);
	z-index: 102;

	@include mobile {
		z-index: 99;
	}
		

	.center-container {
		padding-top: 80px;
		padding-bottom: 80px;
		overflow-y: auto;

		@include mobile {
			padding-top: 60px;
			padding-bottom: 60px;
		}
	}

	.product-retailer-outer {
		display: flex;
		min-height: 150px;

		@include mobile {
			flex-direction: column-reverse;
			padding: 40px 0 30px;
		}

		.product-retailer-product-info {
			display: flex;
			flex-direction: row;

			@include mobile {
				flex-direction: row-reverse;
			}

			.product-retailer-image {
				padding: 20px 0;
				margin-right: 25px;
				width: 160px;
				height: 160px;

				@include tablet {
					height: 100px;
					width: 100px;
				}

				@include mobile {
					height: 75px;
					width: 75px;
				}
			}
			.product-retailer-id {
				margin: auto 0;
				max-width: 550px;

				@include mobile {
					display: flex;
					flex-direction: column-reverse;
					margin: auto auto auto 0;
				}

				h3 {
					@include font-din-regular;
					font-size: 1.5rem;
					color: $c-grey-dark;
					margin: 0 0 10px 0;
				}

				h4 {
					@include font-din-regular;
					font-size: 4.3rem;
					color: $c-brand-colour;
					margin: 0;
					line-height: 110%;

					@include tablet {
						font-size: 3rem;
					}

					@include mobile {
						font-size:  2.4rem;
					}
				}
			}
		}
	}
	.product-retailer-inner {
		background-color: $c-white;
		width: 100%;
		display: flex;
		border: 1px solid $c-line;
		padding: 3% 5%;
		margin: auto;
		border-radius: 10px;

		@include mobile {
			display: block;
			padding: 30px 20px;
			height: initial;
		}

		.product-retailer-ui {
			width: calc(50% - 50px);
			
			@include mobile {
				width: 100%;
				margin-bottom: 30px;
			}

			.product-retailer-heading {
				@include font-din-medium;
				font-size: 2rem;
				color: $c-brand-colour;
				margin: 0 0 20px 0;
			}
			.product-retailer-copy {
				margin: 0 0 40px 0;
			}

			.product-retailer-colour-container {
				background-color: $c-background-grey;
				border-radius: 10px;
				padding: 30px 20px;

				.product-colours-heading {
					@include font-din-medium;
					font-size: 2rem;
					color: $c-grey-dark;
					margin: 0 0 20px 0;
				}

				.product-colours-container {
					display: flex;
					flex-wrap: wrap;
				}

				.single-colour-container {
					display: flex;
					flex-direction: column;
					align-items: center;
					padding: 7px;
					width: 25%;
					margin: 5px 0;

					@include tablet {
						width: calc(100% / 3);
					}

					.single-product-colour {
						width: 63px;
						height: 63px;
						background-color: $c-white;
						border-radius: 100%;
						display: inline-block;
						padding: 4px;

						@include tablet {
							height: 45px;
							width: 45px;
						}

						.colour-inside {
							position: relative;
							width: 55px;
							height: 55px;
							border-radius: 50%;
							border: 1px solid $c-line;

							@include tablet {
								height: 37px;
								width: 37px;
							}
						}

						&.-active {
							border: 1px solid $c-black;
							padding: 3px;
						}

						
					}
					.colour-title {
						margin: 0 auto;
						margin-top: 5px;
						text-align: center;
						color: $c-grey-dark;
					}
				}
			}
		}


		.product-retailers-list {
			width: calc(50% - 50px);
			display: inline-block;
			margin: 0 0 0 auto;
			max-height: 80%;
			padding-top: 20px;
			white-space: pre-wrap;

			@include mobile {
				width: 100%;
				max-height: none;
				padding-top: 0;
			}

			.no-retailers {
				text-transform: initial;
			}

			.single-product-retailer {
				margin: 10px;
				width: calc(33% - 20px);
				max-width: 125px;
				display: inline-block;

				@include tablet {
					width: calc(50% - 20px);
				}

				.product-retailer-logo {
					width: 100%;
					height: auto;
				}
			}
		}
	}

	.exit-product-retailer {
		@include font-din-medium;
		font-size: 2rem;
		display: block;
		color: $c-black;
		text-transform: uppercase;
		padding-left: 15px;
		text-decoration: none;
		margin: auto 0 auto auto;
		transition: 0.2s opacity ease-in-out;
		background-image: url('../../../image/icons/left-chevron-black.svg');
		background-repeat: no-repeat;
		background-position: left center;

		@include mobile {
			margin: 0;
		}

		&.-mobile {
			display: none;
			margin: 40px 0;

			@include mobile {
				display: block;
			}
		}

		&:hover,
		:focus {
			opacity: 0.8;
		}
	}
}