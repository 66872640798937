.repeat-video {
	//background-color: $c-background-grey;

	.product-intro-header-container {
		padding: 70px 20px 70px 20px;

		h1 {
			margin-bottom: 30px;
		}

		@include mobile {
			padding: 50px 20px 0 20px;
		}
	}

	.product-intro-content-container {
		.product-intro-video-gallery {
			margin-bottom: 35px;

			@include mobile {
				margin-top: 20px;
			}

			.single-product-intro-video {

				.intro-video-wrapper,
				.product-intro-video-poster-container {
					width: 100%;
					height: 0;
					position: relative;
					padding-bottom: 56.25%;

					.intro-video-element,
					.product-intro-video-poster {
						width: 100%;
						height: 100%;
						left: 0;
						top: 0;
						position: absolute;
						object-fit: cover;
					}
				}

				.product-intro-video-poster-container {
					.video-poster-button-wrapper {
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
						height: 100%;
						width: 100%;
						display: flex;

						.video-poster-button {
							margin: auto;
							display: block;
							width: 18%;
							height: auto;
							transition: 0.2s opacity ease-in-out;

							&:hover,
							:focus {
								opacity: 0.8;
							}

							.video-poster-button-icon {
								display: block;
								width: 100%;
								height: 100%;
							}
						}
					}
				}

				.product-intro-video-details {
					display: flex;
					flex-direction: row;
					margin: 20px 0;

					.product-intro-video-title {
						@include font-din-medium;
						font-size: 2rem;
						margin: auto 0;
						padding-right: 20px;
						max-width: 800px;
					}

					.play-video-button {
						flex-shrink: 0;
						width: 75px;
						height: 75px;
						transition: 0.2s opacity ease-in-out;
						margin: auto 0 auto auto;

						&:hover,
						:focus {
							opacity: 0.8;
						}

						img {
							width: 100%;
							height: 100%;
						}
					}
				}
			}
		}

		.product-intro-selector-container {
			position: relative;
			width: 100%;
			justify-content: flex-start;
			bottom: 0;
			margin-bottom: 25px;

			.product-intro-selector {
				background-color: $c-grey-lightest;
				transition: 0.2s opacity ease-in-out;

				&:hover,
				:focus {
					opacity: 0.8;
				}

				&.-active {
					background-color: $c-orange;

					&:hover,
					:focus {
						opacity: 1;
					}
				}
			}
		}

		.product-intro-text {
			.product-intro-copy,
			.product-intro-description {
				color: $c-grey-dark;
				margin-bottom: 30px;
				margin-top: 0;
			}

			.product-intro-copy {
				p {
					&:first-of-type {
						margin-top: 0;
					}
				}
			}
		}

		.product-intro-additional {
			background-color: $c-white;
			width: 100%;
			padding: 30px 20px;
			border-radius: 5px;
			border: 1px solid $c-line;
			display: flex;

			.product-intro-featured-icon {
				width: 45px;
				height: 45px;
				min-width: 45px;
				min-height: 45px;
				margin-right: 20px;
			}

			.product-intro-additional-copy {
				.product-intro-additional-heading {
					@include font-din-medium;
					color: $c-grey-dark;
					text-transform: uppercase;
					font-size: 2rem;
					margin: 0 0 15px 0;

				}
				p {
					color: $c-grey-dark;
					font-size: 1.6rem;
					margin: 0 0 15px 0;
				}
			}
		}

		.product-intro-readmore {
			@include font-din-medium;
			color: $c-black;
			display: block;
			margin: 0 0 30px 0;
			transition: 0.2s opacity ease-in-out;

			&:hover,
			:focus {
				opacity: 0.8;
			}
		}
	}
}

.home-video {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-template-rows: auto;
	grid-column-gap: 20px;

	@include tablet {
		display: flex;
		flex-direction: column;
	}

	.left-box {
		@include grid-child(1, 7);

		@include tablet {
			width: 100%;
			order: 2;
		}
	}

	h3 {
		margin: 15px 0 50px;

		@include tablet {
			margin: 15px 0 40px;
		}
	}

	h2 {
		margin: 0 0 10px 0;
	}

	.right-box {
		@include grid-child(8, 5);
		display: flex;
		align-items: center;

		@include tablet {
			width: 100%;
			order: 1;
		}
	}

	.product-intro-video-poster {
		border-radius: 20px;

		@include tablet {
			border-radius: 10px;
		}
	}
}