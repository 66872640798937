.search-bar {
	margin-right: 20px;
	position: relative;

	.input-field {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		background: $c-grey-light;
		border-radius: 20px;


		input {
			border: none;
			margin: 0 13px;
			line-height: 3.5rem;
			outline: 0;
			background: $c-grey-light;
			color: $c-black;
			width: 100px;
			@include font-din-medium;
			font-size: 1.2rem;
			
			&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
				color: $c-brand-colour;
				opacity: 1; /* Firefox */
			}

			&:focus { /* Chrome, Firefox, Opera, Safari 10.1+ */
				width: 150px;
			}
		}

		input[type='search']::-webkit-search-decoration,
		input[type='search']::-webkit-search-cancel-button,
		input[type='search']::-webkit-search-results-button,
		input[type='search']::-webkit-search-results-decoration {
			display: none; 
		}


		.search-icon {
			width: 36px;
			height: 36px;
			background-repeat: no-repeat;
			background-position: center;
			margin-right: 10px;
			transition: 0.2s opacity ease-in-out;
			background-image: url('../../image/icons/search.svg');

			&:hover {
				cursor: pointer;
				opacity: 0.6;
			}
		}
	}

	.dropdown-list {
		background: $c-white;
		width: 380px;
		position: absolute;
		top: 45px;
		right: 0;
		z-index: 1;
		border-radius: 20px;
		overflow: hidden;
		box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
		
		.dropdown {
			background: $c-white;
			@include font-din-regular;
			cursor: pointer;
			padding: 0 10px;

			&:last-child {
				.product-preview {
					border-bottom: 0;
				}
			}
		}

		.product-preview {
			display: flex;
			align-items: center;
			width: 100%;
			padding: 20px 0;
			text-decoration: none;
			border-bottom: 1px solid $c-line;

			&:hover,
			&:focus {
				p {
					color: $c-brand-colour;
				}
			}

			.info-container {
				margin-left: 10px;
			}

			img {
				width: 50px;
				height: 50px;
				object-fit: contain;
			}

			p {
				margin: 0;
				text-align: left;
				text-decoration: none;
			}

			.code {
				font-size: 1.2rem;
				line-height: 1.6rem;
				margin-bottom: 3px;
				color: $c-font-grey;
			}

			.title {
				font-size: 1.4rem;
				line-height: 1.8rem;
				color: $c-black;
				@include font-din-medium;
			}
		}
	}
}