// COLOURS
$c-grey-lightest: #D1D3D4;
$c-grey-light: #F8F7F7;
$c-grey-regular: #e7e7e7;
$c-grey-dark: #58595B;
$c-charcoal: #888B8F;
$c-red-regular: #E0232F;
$c-orange: #F98E19;
$c-mandarin: #FF6040;
$c-green-regular: #046A38;


$c-black: #000000;
$c-white: #FFFFFF;

// VARIABLES
$c-background-grey: $c-grey-light;
$c-font-grey: $c-grey-dark;
$c-brand-red: $c-red-regular;
$c-line: $c-grey-regular;
$c-brand-colour: $c-red-regular;