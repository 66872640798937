// @font-face {
// 	font-family: 'DIN Pro Regular';
// 	src: url('../../fonts/DINPro.eot');
// 	src: local('DIN Pro'), local('DINPro'),
// 		url('../../fonts/DINPro.eot?#iefix') format('embedded-opentype'),
// 		url('../../fonts/DINPro.woff2') format('woff2'),
// 		url('../../fonts/DINPro.woff') format('woff'),
// 		url('../../fonts/DINPro.ttf') format('truetype');
// }

// @font-face {
// 	font-family: 'DIN Pro Medium';
// 	src: url('../../fonts/DINPro-Medium.eot');
// 	src: local('DIN Pro Medium'), local('DINPro-Medium'),
// 		url('../../fonts/DINPro-Medium.eot?#iefix') format('embedded-opentype'),
// 		url('../../fonts/DINPro-Medium.woff2') format('woff2'),
// 		url('../../fonts/DINPro-Medium.woff') format('woff'),
// 		url('../../fonts/DINPro-Medium.ttf') format('truetype');
// }

// @font-face {
// 	font-family: 'DIN Pro Italic';
// 	src: url('../../fonts/DINPro-Italic.eot');
// 	src: local('DIN Pro Italic'), local('DINPro-Italic'),
// 		url('../../fonts/DINPro-Italic.eot?#iefix') format('embedded-opentype'),
// 		url('../../fonts/DINPro-Italic.woff2') format('woff2'),
// 		url('../../fonts/DINPro-Italic.woff') format('woff'),
// 		url('../../fonts/DINPro-Italic.ttf') format('truetype');
// }

// @font-face {
// 	font-family: 'DIN Pro Bold';
// 	src: url('../../fonts/DINPro-Bold.eot');
// 	src: local('DIN Pro Bold'), local('DINPro-Bold'),
// 		url('../../fonts/DINPro-Bold.eot?#iefix') format('embedded-opentype'),
// 		url('../../fonts/DINPro-Bold.woff2') format('woff2'),
// 		url('../../fonts/DINPro-Bold.woff') format('woff'),
// 		url('../../fonts/DINPro-Bold.ttf') format('truetype');
// }

// NEW
@font-face {
	font-family: 'DIN Pro Regular';
	src: local('DIN Pro'), local('DINPro'),
		url('../../fonts/new/DINNextLTPro-Regular.otf') format('opentype');
}

@font-face {
	font-family: 'DIN Pro Medium';
	src: local('DIN Pro Medium'), local('DINPro-Medium'),
	url('../../fonts/new/DINNextLTPro-Medium.otf') format('opentype');
}

@font-face {
	font-family: 'DIN Pro Italic';
	src: local('DIN Pro Italic'), local('DINPro-Italic'),
	url('../../fonts/new/DINNextLTPro-Italic.otf') format('opentype');
}

@font-face {
	font-family: 'DIN Pro Bold';
	src: local('DIN Pro Bold'), local('DINPro-Bold'),
		url('../../fonts/new/DINNextLTPro-Bold.otf') format('opentype');
}

@mixin font-din-regular {
	font-family: 'DIN Pro Regular';
	font-weight: 400;
}

@mixin font-din-medium {
	font-family: 'DIN Pro Medium';
	font-weight: 500;
}

@mixin font-din-italic {
	font-family: 'DIN Pro Italic';
	font-weight: 400;
}

@mixin font-din-bold {
	font-family: 'DIN Pro Bold';
	font-weight: bold;
}