.selectWrapper{
	width: 100%;
	margin-top: 20px;
	padding-left: 39px;

	@include tablet {
		padding-left: 3px;
	}
}

.selectBox {
	border-radius: 50px;
	display: inline-block;
	overflow: hidden;
	background-color: $c-white;
	border: 1px solid #d0d2d3;
	height: 40px;
	outline: none;
	width: 100%;
	padding: 0 20px 0 20px;
	-webkit-appearance: none;
	background-image: url('../../image/icons/dropdown-arrow-black.svg');
	background-repeat: no-repeat;
	background-position: 95% center;
	padding-right: 50px;
}

.brand-colour {
	color: $c-brand-colour;
}

.draw-question {
	margin-left: 20px;
	margin-top: 0;
}