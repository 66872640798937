.general-content-block {
	background-color: $c-white;

	&:nth-child(odd) {
		background: $c-grey-light;
	}
	
	.inner-content {
		display: flex;
		flex-direction: column;
		
		.general-content-block-subtitle {
			@include font-din-medium;
			font-size: 4.3rem;
			color: $c-brand-colour;
			margin-top: 0;
			margin-bottom: 10px;

			@include tablet {
				font-size: 5rem;
			}

			@include mobile {
				font-size: 3.8rem;
			}
		}

		.general-content-block-content {
			font-size: 1.6rem;
		}

		.general-content-block-content-link {
			color: $c-black;
			text-decoration: none;
			font-size: 1.6rem;
			
			&:hover {
				color: $c-brand-colour;
				text-decoration: underline;
			}
		}

		.cta-container {
			padding-top: 50px;
		}
	}
}