.orange-arrow-link {
	width: 45px;
	height: 45px;
	display: block;
	transition: 0.2s opacity ease-in-out;

	&:hover,
	:focus {
		opacity: 0.8;
	}

	img {
		width: 100%;
		height: 100%;
	}
}

.button,
button {
	@include font-din-medium;
	color: $c-black;
	display: inline-block;
	text-decoration: none;
	border: 2px solid $c-black;
	border-radius: 50px;
	padding: 10px 22px 10px 22px;
	min-width: 270px;
	line-height: 130%;
	margin: 0;
	//background-image: url('../../image/icons/right-chevron-black.svg');
	background-repeat: no-repeat;
	background-position: calc(100% - 13px) 50%;
	transition: 0.2s background-color ease-in-out;
	font-size: 1.6rem;
	// background-color: $c-white;
	background-color: transparent;
	cursor: pointer;
	outline: 0;
	max-width: 100%;

	@include mobile-small {
		min-width: auto;
		width: 100%;
	}

	&.-black {
		border: 2px solid $c-black;

		&.-reverse {
			background-color: $c-mandarin;
			border-color: $c-mandarin;
			color: $c-white;
			//background-image: url('../../image/icons/right-chevron-white.svg');

			&:hover,
			:focus {
				//background-image: url('../../image/icons/right-chevron-black.svg');
				background-color: $c-white;
				color: $c-mandarin;
			}
		}
		
		&:hover,
		:focus {
			background-color: $c-orange;
		}

		@include mobile {
			margin: 0;
		}

		@include mobile-small {
			max-width: 500px;
		}

		&.-dark-grey-bg {
			color: $c-white;
			background-color: $c-grey-dark;
		}
	}

	&.-orange {
		border: 2px solid $c-orange;
		background-image: url('../../image/icons/right-chevron-black.svg');

		&.-reverse {
			background-color: $c-mandarin;
			border-color: $c-mandarin;
			color: $c-white;
			background-image: url('../../image/icons/right-chevron-white.svg');

			&:hover,
			:focus {
				background-image: url('../../image/icons/right-chevron-black.svg');
				background-color: $c-white;
				color: $c-mandarin;
			}
		}
		
		&:hover,
		:focus {
			background-color: $c-orange;
			color: $c-white;
			background-image: url('../../image/icons/right-chevron-white.svg');
		}

		@include mobile {
			margin: 0;
		}

		@include mobile-small {
			max-width: 500px;
		}

		&.-grey-background {
			color: $c-black;
			background-color: $c-grey-light;

			&:hover,
			:focus {
				background-color: $c-orange;
				color: $c-white;
				background-image: url('../../image/icons/right-chevron-white.svg');
			}
		}
	}

	&.-red {
		@include font-din-medium;
		font-size: 1.6rem;
		color: $c-brand-colour;
		text-decoration: none;
		display: block;
		background-image: url('../../image/icons/right-chevron-red.svg');
		background-repeat: no-repeat;
		background-position: calc(100% - 20px) 50%;
		max-width: 200px;
	}

	&.-shadow {
		box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
	}
}