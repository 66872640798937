body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	@include font-din-regular;
}

ul,
ol,
li {
	@include font-din-regular;
	font-size: 1.6rem;
	line-height: 155%;
	padding: 0;
}

p {
	@include font-din-regular;
	font-size: 1.6rem;
	line-height: 155%;
}

h4,
h5,
h6 {
	@include font-din-medium;
	font-size: 1.6rem;
	line-height: 155%;
	margin-bottom: 0;
}

a {
	@include font-din-regular;
	font-size: 1.6rem;
	line-height: 155%;
}

.small-heading {
	@include font-din-regular;
	font-size: 1.5rem;
	margin: 0 0 15px 0;
	text-transform: uppercase;
	color: $c-black;
	line-height: 100%;

	@include tablet{
		margin: 0 0 15px 0;
	}
}

.large-subheading {
	@include font-din-regular;
	font-size: 4.3rem;
	color: $c-brand-colour;
	text-transform: capitalize;
	margin: 0 0 25px 0;
	max-width: 640px;
	line-height: 100%;

	@include tablet{
		margin: 0 0 10px 0;
		font-size: 3rem;
	}
}

.section-heading {
	@include font-din-regular;
	font-size: 4.3rem;
	color: $c-brand-colour;
	line-height: 100%;

	@include mobile {
		font-size: 3rem;
		background-image: url('../../image/icons/down-chevron-black.svg');
		background-repeat: no-repeat;
		background-position: 100% 50%;
		padding: 15px 0;
		border-bottom: 1px solid $c-line;
		margin: 0;
	}
}

.alt-section-heading {
	@include font-din-regular;
	font-size: 4.3rem;
	color: $c-brand-colour;
	line-height: 100%;

	@include mobile {
		font-size: 3rem;
	}
}

strong {
	@include font-din-medium;
}