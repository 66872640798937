.category-hero {
	height: 400px;
	padding: 0;
	margin-bottom: 120px;
	background-size: cover;
	background-position: center;

	@include mobile {
		height: 275px;
	}

	.category-hero-inner {
		display: flex;
		height: 100%;
		position: relative;

		@include mobile {
			width: 100%;
			flex-direction: column;
		}
	}

	.grid-child-custom {
		@include grid-child(2, 9);
		
		@include hero-large {
			@include grid-child(2, 10);
		}

		@include tablet {
			@include grid-child(2, 11);
		}
	}

	.category-hero-headings-wrapper {
		position: relative;
		flex-direction: column;
		display: flex;
		justify-content: center;
		width: 40%;
		height: 400px;

		@include mobile {
			position: absolute;
			margin-top: 40px;
			z-index: 1;
			width: 100%;
			display: block;
			height: auto;
		}

		.category-hero-copy {
			@include font-din-medium;
			margin-top: 110px;
			width: 40%;
			font-size: 1.6rem;

			@include mobile {
				margin-top: 10px;
			}
		}

		.category-hero-subtitle {
			@include font-din-medium;
			font-size: 6.5rem;
			color: $c-brand-colour;
			width: 450px;
			max-width: 75%;
			
			@include mobile {
				font-size: 3.8rem;
			}
		}

		a {
			@include font-din-regular;
			font-size: 1.5rem;
			color: $c-black;
			text-decoration: none;

			&:hover,
			&:focus {
				color: $c-brand-colour;
			}
		}
	}

	.category-hero-fg-wrapper {
		width: 60%;
		height: 100%;
		text-align: right;

		@include mobile {
			width: 90%;
			max-width: 275px;
			margin-left: auto;
		}

		img {
			height: calc(400px + 70px);
			top: 10px;
			position: relative;
			max-width: 100%;
			object-fit: contain;

			@include mobile {
				height: 275px;
				top: 70px;
			}
		}
	}
}