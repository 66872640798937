.subscribe-module {
	background-color: $c-white;
	border-top: 1px solid $c-line;
	border-bottom: none;

	&:nth-child(even) {
		background: $c-white;
	}

	&.grey-bg {
		background: $c-grey-light;
	}

	.subscribe-module-inner {
		padding-top: 0;
		padding-bottom: 0;
		min-height: 230px;
		max-height: 400px;
		align-items: center;
		justify-content: center;

		@include tablet {
			padding-top: 20px;
			padding-bottom: 20px;
		}

		@include mobile {
			flex-direction: column;
		}

		.subscribe-module-left,
		.subscribe-module-right {
			width: 50%;

			@include mobile {
				width: 100%;
			}
		}

		.subscribe-module-left {
			padding-right: 30px;
			
			.subscribe-module-heading {
				@include font-din-regular;
				font-size: 4.3rem;
				color: $c-brand-colour;
				max-width: 550px;
				margin: 20px auto 20px 0;

				@include tablet {
					font-size: 3rem;
					max-width: 80%;
				}

				@include mobile {
					margin-top: 0;
					margin-bottom: 50px;
				}
			}
		}

		.subscribe-module-right {
			display: flex;
			flex-direction: column;
			justify-content: center;
			list-style: none;

			@include mobile {
				padding-left: 0;
			}

			.form-field {
				margin-top: 0;
				max-width: 680px;

				@include mobile {
					max-width: 580px;
				}

				input {
					font-size: 2rem;

					@include tablet {
						font-size: 1.6rem;
					}
				}
			}

			.subscribe-module-form {
				width: 100%;

				@include mobile {
					width: 100%;
				}
			}

			.subscribe-module-link {
				margin: 10px 0;
				position: relative;
				padding: 6px 0 0 0;
				display: flex;
				align-items: center;

				&:before {
					background: url('../../image/icons/support-icon-arrow.svg') scroll 0 0 no-repeat;
					content: '';
					width: 23px;
					height: 23px;
					margin-right: 10px;
				}

				@include mobile {
					margin-left: 30px;
				}

				a {
					@include font-din-medium;
					font-size: 2.4rem;
					text-decoration: none;
					color: $c-font-grey;
					transition: 0.2s color ease-in-out;
					line-height: 130%;

					&:hover,
					:focus {
						color: $c-orange;
					}
				}
			}
		}
	}
}