.featured-products {
	overflow: hidden;

	@include mobile {
		padding-bottom: 50px;
	}

	.featured-product-wrapper {
		display: flex;

		.featured-product-heading-container {

			@include mobile {
				max-width: 70%;
			}

			.featured-product-heading {
				@include font-din-regular;
				font-size: 1.5rem;
				text-transform: uppercase;
				margin: 0 0 10px 0;
				color: $c-black;
			}

			.featured-product-subheading {
				@include font-din-regular;
				text-transform: none;
				font-size: 4.3rem;
				color: $c-brand-colour;
				margin: 0;
				padding-right: 20px;

				@include mobile {
					font-size: 3rem;
				}
			}
		}
	}

	.feature-products-button {
		@include desktop-and-mobile-medium;
		margin: auto 0 auto auto;
		min-width: 330px;

		@include mobile {
			display: inline-block;
			margin-left: 0;
			margin-top: 15px;
			max-width: 400px;
		}
	}
}