.mini-images {
	padding: 60px 0;
	background-color: $c-white;

	@include mobile {
		width: 100vw;
		position: relative;
		left: -20px;
		padding: 30px 20px 0;
	}

	.image-container {
		margin-top: 40px;
		flex-wrap: wrap;
		justify-content: flex-start;

		img {
			border-radius: 10px;
		}
	}

	.square-images {
		object-fit: contain;
		box-sizing: border-box;
		border: 1px solid $c-line;

		@media only screen and (min-width: 601px) {
			margin-bottom: 21px;
			width: calc(100% / 3 - 14px);
			margin-right: 21px;

			&:nth-child(3n) {
				margin-right: 0;
			}
		}

		@media only screen and (max-width: 600px) {
			margin-bottom: 20px;
			width: calc(50% - 10px);
			margin-right: 20px;

			&:nth-child(2n) {
				margin-right: 0;
			}
		}

		&:last-child {
			display: none;

			@media only screen and (max-width: 600px) {
				display: inline-block;
			}
		}
	}
}