.cta-banner {
  background: $c-grey-light; 
  .cta-banner-inner {
    display: flex;
    justify-content: space-between;

    @include mobile {
      flex-direction: column;
    }

    .cta-banner-left {
      width: 60%;

      @include mobile {
        width: 100%;
        padding-bottom: 50px;
      }

      .cta-banner-heading {
        max-width: 360px;
      }
    }

    .cta-banner-right {
      display: flex;
      justify-content: center;
      width: 40%;

      @include mobile {
        width: 100%;
        display: block;
      }

      .button.-cta-banner {
        width: 100%;
        margin: auto;
      }
    }
  }
}