.header {
	.header-wrapper {
		position: fixed;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background: $c-white;
		height: 80px;
		border-bottom: 1px solid $c-line;
		z-index: 100;
		background-color: $c-white;

		&.-active {
			z-index: 103;
		}
	
		@include hf-mobile {
			height: 60px;
		}
	
		.header-logo {
			position: absolute;
			left: 0;
			display: flex;
		}
	
		.logo {
			margin-left: 20px;
			width: 113px;
	
			@include hf-mobile {
				width: 90px;
			}
		}
	
		.header-link,
		.category-link,
		.subcategory-link,
		.subcategory-link p {
			color: $c-black;
			text-decoration: none;
			font-size: 1.6rem;
			line-height: 135%;
			margin: 0;
			text-align: center;
			
			&:hover {
				color: $c-brand-colour;
				cursor: pointer;
				font-weight: bold;
	
				p {
					color: $c-brand-colour;
					cursor: pointer;
					font-weight: bold;
				}
			}
		}
	
		.header-link {
			padding: 28px 0;
	
			@include hf-mobile {
				padding: 0;
			}

			&.-active {
				color: $c-brand-colour;
				font-weight: bold;
			}
		}
	
		.category-link,
		.subcategory-link p {
			color: $c-font-grey;
		}
	
		.header-links-desktop {
			display: flex;
			align-items: center;
			width: 50%;
			max-width: 700px;
			justify-content: space-between;
	
			@include hf-mobile {
				display: none;
			}
		}
	
		.search-bar-container {
			position: absolute;
			right: 0;
	
			@include hf-mobile {
				display: none;
			}
		}
	
		.header-mobile {
			display: none;
			position: absolute;
			right: 0;
			padding: 10px;
			margin-right: 10px;
			cursor: pointer;
	
			@include hf-mobile {
				display: inline-block;
			}
	
			.burger-icon {
				width: 28px;
			}
		}
	
		.sub-menu {
			position: absolute;
			width: 100%;
			top: 79px;
			//bottom: -225px;
			min-height: 60px;
			border-top: 1px solid $c-line;
	
			@include hf-mobile {
				display: none;
			}
	
			.sub-menu-container {
				display: flex;
				background: $c-grey-light;
				justify-content: space-around;
				height: 100%;
	
				.sub-menu-item {
					font-size: 1.6rem;
					&:hover {
						> p {
							color: $c-brand-colour;
							cursor: pointer;
							font-weight: bold;
						}
					}
	
					p {
						margin: 0;
					}
	
					&.-active {
						.subcategories-container {
							display: flex;
						}
					}
				}
				/*.sub-menu-container * {
					 pointer-events: none;
				}*/
			}
		}
	
		.small-component {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 0 80px 0 80px;
	
			&:hover {
				color: $c-brand-colour;
				cursor: pointer;
				font-weight: bold;
			}
		}
	
		.category-link {
			display: inline-block;
			padding: 20px;
			height: 100%;
			position: relative;
	
			&.-active {
				&::after {
					content: '';
					display: inline-block;
					background-image: url('../../image/icons/triangle-up-white.svg');
					height: 16px;
					position: absolute;
					bottom: -1px;
					z-index: 1;
					width: 100%;
					left: 0;
					background-repeat: no-repeat;
					background-position: center;
					background-size: contain;
				}
			}
		}
	
		.subcategories-container {
			width: 100vw;
			position: absolute;
			left: 0;
			background: $c-white;
			display: none;
			box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
			padding: 20px 0;
			flex-wrap: wrap;
	
			.subcategory-link {
				padding: 20px 0;
				margin: 0 1%;
				width: 190px;
				flex-shrink: 0;			
	
				img {
					width: 100%;
					max-width: 140px;
					object-fit: contain;
					border-radius: 10px;
					margin-bottom: 10px;
				}
			}
		}

		.hamburger {
			display: none;

			@include hf-mobile {
				display: inline-block;
			}
	
			&.-active {
				position: fixed;
				z-index: 2;
			}
		}
	}

	.mobile-navigation {
		display: none;

		@include hf-mobile {
			&.-active {
				display: flex;
			}
		}

		&.-active {
			background-color: $c-white;
			flex-direction: column;
			align-items: center;
			// if the nav items will never overflow the viewport, 'justify-content: center' will work. If the nav items will overflow the viewport however, it wont. Use 'justify-content: flex-start' along with the desired padding in these instances
			justify-content: flex-start;
			padding: 60px 0;
			position: fixed;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 102;
			overflow: scroll;
			// momentum-based scrolling for some handheld devices if items overflow a div
			-webkit-overflow-scrolling: touch;
		}

		.search-bar-container {
			background-color: $c-grey-regular;
			width: 100vw;
			padding: 15px 20px;

			input {
				width: 100%;
				background: $c-white;

				&:focus {
					width: 100%;
				}
			}
			
			.search-bar {
				margin-right: 0;

				.input-field {
					background: $c-white;
				}
			}

			.dropdown-list {
				width: 100%;
				//display: none;
			}
		}

		.header-links-mobile {
			display: flex;
			flex-direction: column;
			width: 100%;
		}

		.header-link,
		.category-link,
		.subcategory-link {
			position: relative;
			text-decoration: none;
			width: 100%;
			display: inline-block;

			p {
				font-size: 1.6rem;
				line-height: 120%;
				color: $c-black;
				margin: 0;
			}

			&.-active {
				> p {
					color: $c-brand-colour;
					@include font-din-medium;
				}
			}

			&.-with-dropdown {
				&:before {
					content: '';
					display: inline-block;
					background-image: url('../../image/icons/up-chevron-black.svg');
					height: 16px;
					position: absolute;
					width: 15px;
					right: 20px;
					top: 26px;
					background-repeat: no-repeat;
					background-position: center;
					background-size: contain;
					transform: rotate(180deg);
					transition: transform .25s;
				}

				&.-active {
					&:before {
						transform: rotate(360deg);
					}
				}
			}
		}

		.header-link {
			> p {
				padding: 25px 20px;
				border-bottom: 1px solid $c-line;
				cursor: pointer;
			}

			.category-wrapper {
				display: none;
			}

			// Show submenu wrapper
			&.-active {
				.category-wrapper {
					display: block;
				}
			}
		}

		.category-link {
			> p {
				color: $c-font-grey;
				padding: 25px 20px 25px 40px;
				border-bottom: 1px solid $c-line;
				background-color: $c-background-grey;
				cursor: pointer;
			}

			.subcategory-wrapper {
				display: none;
			}

			// Show submenu wrapper
			&.-active {
				.subcategory-wrapper {
					display: block;
				}
			}
		}

		.subcategory-link {
			padding: 10px 20px;
			width: calc(100% - 40px);
			margin-left: 20px;
			display: flex;
			border-bottom: 1px solid $c-line;

			img {
				width: 70px;
				height: 70px;
				object-fit: contain;
			}

			> p {
				padding: 25px 20px;
				background-color: $c-white;
			}
		}
	}
}
