.home {
	.help-module {
		border-top: none;
		background-color: $c-grey-dark !important;
		color: $c-white;
		
		.help-module-heading {
			color: $c-white;
		}

		.help-module-link {
			a {
				color: $c-white;
			}
		}
	}
}