.recipe-tags {
	margin-top: 20px;
	padding-top: 40px;
	padding-bottom: 40px;

	.recipe-tags-heading {
		@include font-din-regular;
		font-size: 1.5rem;
		text-transform: uppercase;
		display: block;
		margin-bottom: 5px;
		margin-top: 15px;
	}

	.recipe-tags-container {

		.single-recipe-tag {
			color: $c-grey-dark;
			font-size: 1.5rem;
			text-transform: uppercase;
			display: inline-block;
			margin: 0 10px 10px 0;
			transition: 0.2s color ease-in-out;

			&:hover,
			:focus {
				color: $c-brand-colour;
			}

			&:last-of-type {
				margin-left: 0;
			}
		}
	}
}