.form-container {
	/* Intro Headings */

	.form-type {
		@include font-din-regular;
		font-size: 1.5rem;
		text-transform: uppercase;
		margin: 0 20px 0 0;
	}

	.form-heading {
		@include font-din-regular;
		color: $c-brand-colour;
		font-size: 4.3rem;
		margin: 0;
		margin-top: 15px;
		text-transform: none;
		line-height: 100%;
		margin-bottom: 40px;
	}

	/* Form */

	.form-field {
		margin-top: 15px;
	}


	/* Input and Textarea */

	.-input,
	.-textarea {
		text-align: left;

		label {
			@include font-din-regular;
			display: inline-block;
			font-size: 1.3rem;
			line-height: 130%;
			color: $c-grey-dark;
			margin: 0 0 8px 20px;
		}

		&.-searchform {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			background: $c-white;
			border-radius: 40px;
			box-sizing: border-box;
			border: 1px solid transparent;
			

			&:focus,
			:hover {
				border: 1px solid $c-charcoal;
			}
			
			&:focus-within {
				border: 1px solid $c-charcoal;
			}

			.-search,
			.search-icon {
				border: none;
				outline: 0;

				&:focus,
				:hover {
					border: none;
					outline: 0;
				}
			}
		}
	}

	input,
	textarea,
	select {
		@include font-din-medium;
		width: 100%;
		border-radius: 25px;
		border: 1px solid $c-grey-regular;
		padding-left: 20px;
		font-size: 1.4rem;
		background-color: $c-grey-light;

		&::placeholder {
			@include font-din-italic;
			color: $c-font-grey;
			font-weight: 600;
		}

		&:focus {
			border: 1px solid $c-charcoal;
			outline: 0;
		}
	}

	select {
		height: 50px;
		padding-right: 20px;
		-webkit-appearance: none;
		background-image: url('../../image/icons/down-chevron-black.svg');
		background-repeat: no-repeat;
		background-position: calc(100% - 20px) 50%;
	}



	input[type='search']::-webkit-search-decoration,
	input[type='search']::-webkit-search-cancel-button,
	input[type='search']::-webkit-search-results-button,
	input[type='search']::-webkit-search-results-decoration {
		display: none; 
	}

	input[type='search'] {
		border: none;
		margin: 0 13px;
		line-height: 3.5rem;
		outline: 0;
		background: $c-grey-light;
		color: $c-black;
		width: 100px;
		@include font-din-medium;
		font-size: 1.2rem;
		
		&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
			color: $c-brand-colour;
			opacity: 1; /* Firefox */
		}

		&:focus { /* Chrome, Firefox, Opera, Safari 10.1+ */
			width: 150px;
		}
	}

	.search-icon {
		width: 36px;
		height: 36px;
		background-repeat: no-repeat;
		background-position: center;
		margin-right: 10px;
		transition: 0.2s opacity ease-in-out;
		background-image: url('../../image/icons/search.svg');

		&:hover {
			cursor: pointer;
			opacity: 0.6;
		}
	}

	input {
		height: 50px;

		&.large {
			height: 70px;
			border-radius: 50px;
			
			@include tablet {
				height: 50px;
			}
		}

		&.enter-icon {
			background-size: 36px;
			background-repeat: no-repeat;
			background-position: 99%;
			background-image: url('../../image/icons/enter-orange.svg');

			&.large {
				background-size: 53px;

				@include tablet {
					background-size: 36px;
				}
			}
		}

		&.white-bg {
			background-color: $c-white;
			&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
				color: $c-brand-colour;
				opacity: 1; /* Firefox */
			}
		}
	}

	textarea {
		height: 100px;
		padding: 15px 20px;
		resize: none;
	}


	/* Submit */

	.-submit {
		margin-top: 40px;

		button {
			width: 50%;

			@include tablet {
				width: 100%;
			}
		}
	}

	/* upload file input */
	.file-upload {
		display: none;
	}

	.file-upload-section {

		display: flex;
		justify-content: space-between;
		//align-items: center;
		margin-top: 30px;

		span {
			margin-left: 20px;
			font-size: 1.6rem;
		}

		.upload-button {
			padding: 10px 25px;
			min-width: 25px;
			background: $c-brand-colour;
			color: $c-white;
			border: 0;
			transition: opacity .15s linear;

			&:hover {
				opacity: 0.8;
			}
		}
	}

	

	/* Checkbox */

	.-checkbox {
		display: flex;
		align-items: center;
		margin-top: 40px;
		position: relative;

		&.-consent {
			margin-top: 15px;
		}

		label,
		a {
			@include font-din-regular;
			display: inline-block;
			font-size: 1.3rem;
			line-height: 130%;
			color: $c-grey-dark;
		}

		input {
			-webkit-appearance: none;
			width: 20px;
			height: 20px;
			border: 1px solid $c-line;
			margin-right: 15px;
			cursor: pointer;
			padding: 3px;
			box-sizing: border-box;
			outline: 3px solid $c-white;
			outline-offset: -4px;
			margin-left: 0;
			flex-shrink: 0;
			border-radius: 0;

			&:hover {
				background-color: rgba(224,35,47,0.3);
			}

			&:checked {
				background-color: $c-brand-colour;
			}
		}

		a {
			transition: 0.2s color ease-in-out;

			&:hover,
			&:focus {
				text-decoration: underline;
				color: $c-brand-colour;
			}
		}
	}

	.datepicker {
		padding-right: 15px;
	}

	/* Helper Text */

	.helper-text {
		color: $c-orange;
		font-size: 1.1rem;
		visibility: hidden;
		margin: 0;

		&.-active {
			visibility: visible;
		}
	}

	.error-msg {
		color: $c-brand-colour;
		font-size: 2rem;
		margin-top: 20px;
	}

	.success-msg {
		color: $c-brand-colour;
		font-size: 2rem;
		margin-top: 20px;
	}
}