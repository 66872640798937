.product-warranty {
	@include mobile-accordion;
	@include section-accordion;

	.product-warranty-inner {
		color: $c-grey-dark;

		@include mobile {
			padding: 40px 0;
		}

		.product-warranty-intro {
			margin: 0 0 50px 0;

			.product-warranty-intro-text {
				width: 440px;
				margin: 0 0 25px 0;
			}
		}
		
		.product-warranty-container {
			margin-bottom: 60px;

			.product-warranty-heading {
				@include font-din-medium;
				font-size: 2rem;
				color: $c-black;
				text-transform: uppercase;
				margin: 0 0 20px 0;
			}
			
			.single-product-warranty {
				.warranty-line {
					padding: 10px 20px;
					width: 100%;
					display: flex;
					border-bottom: 1px solid $c-line;

					&.-top {
						background-color: $c-background-grey;
						border-top: 1px solid $c-line;

						.single-product-warranty-model {
							@include font-din-medium;
							font-size: 1.3rem;
							margin: 0;
							color: $c-black;
						}
					}

					.single-product-warranty-heading {
						@include font-din-medium;
						font-size: 1.3rem;
						width: 135px;
						margin: 0 30px 0 0;
					}

					.single-product-warranty-details {
						text-transform: capitalize;
						font-size: 1.3rem;
						margin: 0;
					}
				}
			}
		}

		.product-warranty-guarantee {
			.warranty-guarantee-text {
				margin: 25px 0;
			}

			.warranty-guarantee-link {
				display: inline-block;
				@include font-din-medium;
				color: $c-black;
				margin-top: 20px;
				transition: 0.2s opacity ease-in-out;

				&:hover,
				&:focus {
					opacity: .8;
				}
			}
		}
	}
}