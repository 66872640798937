.article-hero {
	&.-full-width {
		padding: 0;

		.article-hero-desktop {
			padding: 80px 0;
			background-size: cover;
			height: 60vh;
			min-height: 500px;
			background-position: center;
			background-repeat: no-repeat;

			@include mobile-small {
				display: none;
			}
		}

		.article-hero-mobile {
			padding: 40px 0;
			display: none;
			height: 60vh;
			min-height: 430px;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;

			@include mobile-small {
				display: block;
			}
		}

		.article-hero-inner {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
		}
	}


	&.-contained {
		background: linear-gradient(to bottom, 
		$c-background-grey 65%, $c-white 35%);
		padding-bottom: 0;

		.article-hero-inner {
	
			.article-hero-image {
				@include desktop-and-mobile-small;
				width: 100%;
				height: auto;
				border-radius: 10px;
				margin-top: 60px;
	
				@include tablet {
					margin-top: 40px;
				}
			}
		}
	}

	.article-hero-title {
		margin-bottom: 7px;

		@include mobile {
			margin-bottom: 15px;
		}
	}

	.article-hero-tagline {
		font-size: 3rem;
		line-height: 110%;

		@include tablet {
			font-size: 2.4rem;
		}
	}

	.article-hero-subtitle {
		@include font-din-medium;
		font-size: 6.5rem;
		color: $c-brand-colour;
		margin-bottom: 0;

		@include tablet {
			font-size: 5rem;
		}

		@include mobile {
			font-size: 3.8rem;
		}
	}

	.article-hero-date {
		font-size: 1.5rem;
		text-transform: uppercase;
		color: $c-grey-dark;
	}

	&.collection-full-hero {
		.article-hero-inner {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			text-align: left;
		}
	}
}