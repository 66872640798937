html {
	// this allows for easy use of 'rem' to specify font sizes, eg. 16px = 1.6rem
	font-size: 62.5%;

	&.overflow-hidden {
		overflow: hidden;
	}
}

body,
html {
	margin: 0;
	padding: 0;
}

header,
main,
section,
div,
a,
input,
label,
textarea,
footer {
	// without 'border-box', padding will be added to a specified element size, rather than being contained within it
	box-sizing: border-box;
}

main {
	display: block;
}

section {
	padding: 80px 0;
	position: relative;

	@include mobile {
		padding: 40px 0;
	}

	&.-remove-padding {
		// add '.-remove-padding' to a section if you dont want any padding on your section
		padding: 0;
	}

	&.-remove-padding-top {
		// add '.-remove-padding-top' to a section if you dont want any padding on your section
		padding-top: 0;
	}

	&.-remove-padding-bottom {
		// add '.-remove-padding-bottom' to a section if you dont want any padding on your section
		padding-bottom: 0;
	}
}

.app {

	@include mobile {
		overflow: hidden;
	}
}

.master-container {
	padding-top: 80px;
	
	@include hf-mobile {
		padding-top: 60px;
	}
}

.centred-flex {
	display: flex;
}

.grid-parent {
	// .grid-parent applies a 12 column grid to the div
	@include grid-parent(12);

	.grid-child-12 {
		// .grid-child-12 applies a 12 column grid that is centred
		@include grid-child(1, 12);
	}

	.grid-child-10 {
		// .grid-child-10 applies a 10 column grid that is centred
		@include grid-child(2, 10);
	}

	.grid-child-8 {
		// .grid-child-8 applies a 8 column grid that is centred
		@include grid-child(3, 8);
	}

	.grid-child-6 {
		// .grid-child-8 applies a 8 column grid that is centred
		@include grid-child(4, 6);

		@include tablet {
			@include grid-child(3, 8);
		}
	}

	.grid-child-6-3 {
		// .grid-child-8 applies a 8 column grid that is centred
		@include grid-child(3, 6);

		@include tablet {
			@include grid-child(3, 8);
		}
	}

	.narrow-left {
		@include grid-child(1, 3);
	}

	.wide-right {
		@include grid-child(4, 9);
	}

	.middle-medium {
		@include grid-child(4, 6);

		@include tablet {
			@include grid-child(3, 8);
		}
	}

	.middle-large{
		@include grid-child(3, 7);

		@include tablet {
			@include grid-child(2, 8);
		}
	}

	.middle-largest {
		@include grid-child(3, 8);

		@include tablet {
			@include grid-child(2, 9);
		}
	}

	.right-medium {
		@include grid-child(6, 7);

		// @include tablet {
		// 	@include grid-child(5, 8);
		// }
	}

	.right-medium-product-hero {
		@include grid-child(5, 8);

		// @include tablet {
		// 	@include grid-child(5, 8);
		// }
	}

	.right-small {
		@include grid-child(6, 5);

		@include tablet {
			@include grid-child(5, 6);
		}
	}

	.left-small {
		@include grid-child(1, 5);

		@include tablet {
			@include grid-child(1, 5);
		}
	}

	.left-small-product-hero {
		@include grid-child(1, 4);

		@include tablet {
			@include grid-child(1, 4);
		}
	}

	.left-medium {
		@include grid-child(1, 7);

		@include tablet {
			@include grid-child(1, 6);
		}
	}

	.help-left {
		@include grid-child(2, 4);

		@include tablet {
			@include grid-child(2, 5);
		}
	}

	.form-left {
		@include grid-child(3, 6);

		@include tablet {
			@include grid-child(3, 8);
		}
	}
}


.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.flex-column {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
}

.flex-column-around {
	display: flex;
	justify-content: space-around;
	align-items: flex-start;
	flex-direction: column;
	height: 100%;
}

.flex-column--center-around {
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-direction: column;
	height: 100%;
}

.flex-column-between {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-direction: column;
	height: 100%;
}

.flex-row-between {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
	width: 100%;
}

.flex-row-around {
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-direction: row;
	width: 100%;
}

.flex-row-even {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	flex-direction: row;
	width: 100%;
	flex-wrap: wrap;
}

.flex-row-center {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
}

.flex-row {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.space-around {
	justify-content: space-around;
}

.center-container,
.centred-flex {
	position: relative;
	width: 100%;
	height: 100%;
	max-width: 1800px;
	padding: 0 40px;
	margin: 0 auto;

	@include mobile {
		padding: 0 20px;
	}

	&.-break-to-full {
		@include tablet {
			padding: 0;
		}
	}
}