.collection-range {
	padding: 0px 0 150px 0;

	@include tablet {
		height: 100%;
		padding: 0 0 88px 0;
	}

	.collection-range-headings-container {
		height: 100%;
		display: flex;
		flex-direction: column;

		.collection-range-title,
		.collection-range-description {
			max-width: 260px;
			width: 95%;
		}

		.collection-range-description {
			margin: 0 0 25px 0;

			@include tablet {
				margin: 0 0 10px 0;
			}
		}

		.collection-range-button {
			margin-top: 10px;
			margin-bottom: 25px;
		}
	}

	.product-range-image-container {

		.product-range-image {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 20px;

			@include tablet {
				border-radius: 10px;
			}
		}
	}

	.collection-range-cta {
		@include font-din-medium;
		@include desktop-and-mobile-medium;
		margin-top: auto;
		text-decoration: none;
		color: $c-brand-colour;
		background-image: url('../../image/icons/red-right-arrow.svg');
		background-repeat: no-repeat;
		background-position: right center;
		max-width: 210px;
		transition: 0.2s opacity ease-in-out;

		&:hover,
		&:focus {
			opacity: .8;
		}

		&.-mobile {
			@include tablet {
				margin-top: 25px;
			}
		}
	}
}