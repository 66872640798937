.social-share-block {
	margin-top: 40px;

	.social-share-wrapper {
		display: flex;
		align-items: center;
	}

	.social-link {
		display: flex;
		align-items: center;
		margin-left: 20px;
	}
}