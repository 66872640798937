.product-sticky-image-container {
	position: sticky;
	top: 100px;
	margin-top: 20px;

	@include mobile {
		display: none;
	}

	.product-image-sticky {
		object-fit: contain;
		position: relative;
		left: -50%;
		width: 150%;
		max-height: calc(100vh - 110px);
	}
}