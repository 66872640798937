.wtb-page {
	.link-wrapper {
		display: flex;
		flex-wrap: wrap;
	}

	.single-product-retailer {
		width: 25%;
		padding: 20px;

		@include tablet {
			width: calc(100% / 3);
		}

		@include mobile {
			width: 50%;
		}

		img {
			width: 100%;
		}
	}
}