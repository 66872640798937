.card-module {
	background: $c-background-grey;
}

.card-wrapper {
	flex: 3;
	display: flex;
	flex-wrap: wrap;

	.selectWrapper {
		margin-top: 0;
	}

	&.-desktop {
		@include mobile {
			display: none;
		}
	}

	.card {
		text-decoration: none;
		background-color: $c-white;
		border-radius: 10px;
		position: relative;
		display: inline-block;

		// Flex Cards - Can be used in place of masonry grid
		// @media only screen and (min-width: 1201px) {
		// 	margin-bottom: 4.5%;
		// 	width: calc(100% / 3 - 6%);
		// 	margin-right: 9%;

		// 	&:nth-child(3n) {
		// 		// use 'nth-child' on the last card in the row to remove the margin-right'
		// 		margin-right: 0;
		// 	}
		// }

		// @media only screen and (min-width: 901px) and (max-width: 1200px) {
		// 	margin-bottom: 21px;
		// 	width: calc(100% / 3 - 14px);
		// 	margin-right: 21px;

		// 	&:nth-child(3n) {
		// 		margin-right: 0;
		// 	}
		// }

		// @media only screen and (min-width: 621px) and (max-width: 900px) {
		// 	margin-bottom: 20px;
		// 	width: calc(50% - 10px);
		// 	margin-right: 20px;

		// 	&:nth-child(2n) {
		// 		margin-right: 0;
		// 	}
		// }

		// @media only screen and (max-width: 620px) {
		// 	margin-bottom: 20px;
		// 	width: 100%;
		// 	margin-right: 0;
		// }

		.card-image {
			width: 100%;
			height: auto;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
		}

		.card-image-wrapper {
			img {
				width: 100%;
				height: auto;
				border-top-left-radius: 10px;
				border-top-right-radius: 10px;
			}
		}

		.card-featured-icon {
			position: absolute;
			right: 20px;
			top: -20px;
			width: 4vw;
			min-width: 50px;
			max-width: 60px;

			@include tablet {
				right: 10px;
				top: -17px;
				width: 50px;
			}

			@include mobile-small {
				top: -15px;
			}
		}

		.content-wrapper {
			padding: 32px 16px 40px;
			margin-top: -4px;
			background: $c-white;
			color: $c-black;
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;

			.card-inner {
				display: flex;
				flex-direction: column;

				.date {
					font-size: 1.5rem;
					text-transform: uppercase;
					color: #58595b;
					margin: 0;

					@include mobile {
						font-size: 1.4rem;
					}
				}

				.title {
					font-size: 3rem;
					color: $c-brand-colour;
					padding: 10px 40px 10px 0;
					margin: 0 0 13px;
					line-height: 110%;

					@include mobile {
						font-size: 2.4rem;
					}
				}

				.des-container {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;

					.des {
						font-size: 1.6rem;
						color: $c-font-grey;
						margin: 0;
						padding-right: 46px;
						line-height: 155%;

						@include mobile {
							font-size: 1.4rem;
							line-height: 130%;
						}
					}

					.arrow-icon {
						width: 37px;
						position: absolute;
						bottom: 30px;
						right: 20px;
					}
				}
			}
		}
	}

	// Masonry Grid
	.masonry-grid {
		display: -webkit-box; /* Not needed if autoprefixing */
		display: -ms-flexbox; /* Not needed if autoprefixing */
		display: flex;
		margin-left: -30px; /* gutter size offset */
		width: calc(100% + 30px);
	}

	.masonry-grid-column {
		padding-left: 30px; /* gutter size */
		background-clip: padding-box;
	}

	/* Style your items */
	.masonry-grid-column > .card {
		/* change div to reference your elements you put in <Masonry> */
		margin-bottom: 30px;
	}

	@media (max-width: 1250px) {
		.masonry-grid {
			margin-left: -20px; /* gutter size offset */
			width: calc(100% + 20px);
		}
		.masonry-grid-column {
			padding-left: 20px; /* gutter size offset */
		}
		.masonry-grid-column > .card {
			margin-bottom: 20px; /* space between items */
		}
	}
}
