@import './base/breakpoints';
@import './base/mixins';
@import './base/colours';
@import './base/fonts';
@import './base/typography';
@import './base/layout';
@import './base/form';
@import './base/loader';

@import './page/home';
@import './page/product';
@import './page/template-page';
@import './page/collection';
@import './page/faq-page';
@import './page/contact-page';
@import './page/support';
@import './page/search-page';
@import './page/where-to-buy-page';

@import './component/buttons';
@import './component/footer';
@import './component/swiper';
@import './component/accordion';
@import './component/header';
@import './component/search-bar';
@import './component/card-wrapper';
@import './component/content-block';
@import './component/social-share-block';

@import'./partial/recipe-tip';
@import'./partial/collection-range';
@import'./partial/product-range';
@import'./partial/image-break';
@import'./partial/help-module';
@import'./partial/subscribe-module';
@import'./partial/cta-banner';
@import'./partial/article-hero';
@import'./partial/lifestyle-slider';
@import'./partial/category-hero';
@import'./partial/recipe-brief';
@import'./partial/warranty-table';
@import'./partial/contact-block';
@import'./partial/service-agent';
@import'./partial/recipe-tags';
@import'./partial/related-articles';
@import'./partial/home-hero';
@import'./partial/featured-products';
@import'./partial/product-list';
@import'./partial/dynamic-block';

@import'./partial/home/home-promo';
@import'./partial/card-contianer';

@import'./partial/forms';

@import'./partial/product/specifications';
@import'./partial/product/reviews';
@import'./partial/product/inclusions';
@import'./partial/product/warranty';
@import'./partial/product/product-intro';
@import'./partial/product/product-hero';
@import'./partial/product/product-content';
@import'./partial/product/product-retailers';
@import'./partial/product/sticky-image';
@import'./partial/product/product-nav';
@import'./partial/product/manual';
@import'./partial/repeat-video';

@import'./partial/collection/mini-images';

@import './component/general-content-block';

@import './lib/react-slider';
@import './lib/hamburger';
