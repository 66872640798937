.warranty-table {
	@include mobile {
		position: relative;
		width: 100vw;
		left: -20px;
	}

	.single-accordion {
		border-bottom: 1px solid $c-white;

		.accordion-title {
			@include font-din-regular;
			font-size: 1.6rem;
			display: block;
			width: 100%;
			background: $c-charcoal;
			padding: 15px 50px 15px 20px;
			color: $c-white;
			text-decoration: none;
			background-image: url('../../image/icons/down-chevron-white.svg');
			background-repeat: no-repeat;
			background-position: calc(100% - 20px) 50%;
			transition: 0.2s opacity ease-in-out;

			&:hover,
			:focus {
				opacity: 0.8;
			}

			&.-active {
				background-image: url('../../image/icons/up-chevron-white.svg');
			}
		}
		.single-product-warranty {
			margin: 20px 20px;

			.warranty-line {
				padding: 0px 20px;
				border-bottom: 1px solid $c-line;
				display: flex;
				
				&:first-of-type {
					border-top: 1px solid $c-line;
				}

				&.-top {
					background-color: $c-background-grey;
				}

			}
			.single-product-warranty-heading,
			.single-product-warranty-details,
			.single-product-warranty-model {
				@include font-din-medium;
				font-size: 1.6rem;
				color: $c-grey-dark;
			}

			.single-product-warranty-model {
				color: $c-black;
				margin: 10px 0;
			}

			.single-product-warranty-details {
				text-transform: capitalize;
				@include font-din-regular;
			}

			.single-product-warranty-heading {
				min-width: 210px;

				@include mobile {
					min-width: 150px;
				}
			} 
		}
	}
}