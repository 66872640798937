.product-hero {
	position: relative;

	@include mobile {
		height: auto;
		max-height: none;
		padding-bottom: 40px;
	}

	.product-hero-inner {
		height: 100%;
	
		.product-hero-info {
			height: 100%;

			.product-hero-category {
				a {
					@include font-din-regular;
					font-size: 1.5rem;
					color: $c-black;
					text-decoration: none;

					&:hover,
					&:focus {
						color: $c-brand-colour;
					}
				}
			}

			.product-hero-title {
				@include font-din-medium;
				font-size: 6.5rem;

				@include tablet {
					font-size: 5rem;
				}

				@include mobile {
					font-size: 3.8rem;
					margin-bottom: 30px;
				}
			}

			.product-hero-model {
				@include font-din-regular;
				font-size: 1.5rem;
				text-transform: uppercase;
				color: $c-grey-dark;
			}
		}

		.product-hero-focus {
			height: 100%;

			.product-hero-image-container {
				display: flex;
				justify-content: center;
				height: 53vw;
				max-height: calc(100vh - 450px);
				min-height: 600px;

				@include mobile {
					min-height: 200px;
					height:  auto;
					padding-bottom: 0;
				}

				.product-hero-image {
					height: 120%;
					position: relative;
					top: -10%;
					// object-fit: contain;
					max-width: calc(100% - 105px);
					display: inline-flex;
					justify-content: center;
					align-items: center;
					margin: 0 auto;

					img {
						max-width: 100%;
						max-height: 100%;
						object-fit: contain;
						border-radius: 10px;
					}

					@include mobile {
						height: auto;
						margin:  20px auto 0;
						max-width: calc(100% - 50px);
					}
				}
			}
			.product-hero-ui {
				position: absolute;
				right: 40px;
				top: 2%;
				display: flex;

				@include mobile {
					position: relative;
					display: block;
					top: 0;
					right: 0;
					left: 0;
					width: 100%;
				}

				.colour-award-container {
					margin-right: 40px;

					@include mobile {
						margin: 0;
					}
				}

				.product-colours-container {
					width: 84px;
					max-height: 370px;
					margin: 0;
					display: flex;
					flex-wrap: wrap;
					flex-direction: column;
					align-items: center;
					margin-left: auto;

					@include mobile {
						margin: 15px auto 0;
						width: 100%;
						flex-direction: row;
						justify-content: center;
					}

					&.-many {
						.single-product-colour {
							width: 40px;
							height: 40px;
							margin: 5px 5px;

							.colour-inside {
								width: 32px;
								height: 32px;
							}
						}
					}
				
					.single-product-colour {
						width: 46px;
						height: 45px;
						border-radius: 100%;
						display: block;
						margin: 5px 0 5px;
						padding: 4px;
						position: relative;
						cursor: pointer;

						&:last-child {
							margin-right: 0;
						}

						@include mobile {
							display: inline-block;
							margin: 0 5px 0 0;
						}

						&.-active {
							border: 1px solid $c-black;
							padding: 3px;
							background-color: $c-white;
						}

						&:last-of-type {
							margin-bottom: 0;
						}

						.colour-inside {
							position: relative;
							width: 38px;
							height: 37px;
							border-radius: 50%;
							border: 1px solid $c-line;
						}
					}
				}
			}
		}
	}
}

.product-hero-price-details {
	@include desktop-and-mobile-medium;

	.product-hero-price {
		@include font-din-medium;
		font-size: 3.6rem;
		margin: 35px 0 10px;
	}
	.product-hero-buy {
		margin: 35px 0 0;
		width: 290px;
		min-width: auto;

		@include mobile {
			width: 100%;
		}
	}

	.ratings-wrapper {
		a {
			transition: 0.2s opacity ease-in-out;

			&:hover,
			:focus {
				opacity: 0.6;
			}
		}
	}
}

.product-hero-icon-container {
	@include desktop-and-mobile-medium;
	margin-bottom: 3vh;

	&.-mobile {
		position: absolute;
		top: 20%;
		right: 20px;

		@include mobile-small {
			top: 25%;
		}
	}

	.product-award-link,
	.product-featured-icon-link {
		margin-left: 10px;
		transition: 0.2s opacity ease-in-out;
		display: inline-block;

		@include mobile {
			margin: 0 0 10px 0;
			display: block;
		}

		.product-award-icon {
			width: 85px;
			height: 85px;

			@include mobile {
				width: 50px;
				height: 50px;
				display: block
			}
		}
	}

	a {
		&.product-award {
			transition: transform .25s linear;

			&:hover,
			:focus {
				transform: scale(1.05);
			}
		}
	}

	.product-award {
		&:last-child {
			margin-right: 0;

			@include mobile {
				margin-bottom: 0;
			}
		}
	}
}

.product-hero-gallery {
	@include desktop-and-mobile-medium;
	position: relative;
	overflow: hidden;

	&.-desktop {
		height: 450px;
		width: 70px;
		display: inherit;
		overflow: visible;

		@include mobile {
			display: none;
		}

		.swiper-wrapper {
			display: flex;
			flex-direction: column;
			height: 100%;
			width: 100%;
		}

		.swiper-container {
			height: calc(100% - 40px);
			overflow: hidden;
			margin: 2px 0;
		}
	}

	&.-mobile {
		min-height: 70px;
		width: calc(100% + 20px);
		margin-top: 25px;

		.swiper-wrapper {
			display: inline-flex;
			flex-direction: row;
		}

		.swiper-container {
			height: 100%;
		}
	}

	.swiper-container {
		margin: auto;
		width: 100%;
		position: relative;
	}

	.swiper-wrapper {
		display: flex;
		flex-direction: column;
	}

	.product-hero-gallery-button {
		border: 1px solid $c-line;
		background-color: $c-white;
		border-radius: 4px;

		.product-hero-gallery-image {
			object-fit: contain;
			height: 100%;
			width: 100%;
		}
	}

	.hero-slider-prev,
	.hero-slider-next {
		position: absolute;
		margin: auto;
		width: 100%;
		height: 20px;
		left: 0;
		right: 0;
		background-color: $c-white;
		background-position: center;
		background-size: 20px 20px;
		background-repeat: no-repeat;

		&.swiper-button-disabled {
			display: none;
		}
	}

	.hero-slider-prev {
		top: -20px;
		background-image: url('../../../image/icons/up-chevron-orange.svg');
	}

	.hero-slider-next {
		bottom: -20px;
		background-image: url('../../../image/icons/down-chevron-orange.svg');
	}
}