.features {
	@include mobile-accordion;
	@include section-accordion;

	h2 {
		margin-bottom: 15px;

		@include mobile {
			margin-bottom: 0;
		}
	}
}